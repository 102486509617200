export class AppConfig {
  static animals = true;
  static env: 'local' | 'prod' = process.env.REACT_APP_ENV as 'local' | 'prod';
  static get host() {
    switch (this.env) {
      case 'local':
        return 'https://localhost:3203';
      case 'prod':
        return 'https://api.emote.bingo';
    }
  }
  static get socketHost() {
    switch (this.env) {
      case 'local':
        return 'ws://localhost:3001';
      case 'prod':
        return 'wss://ws.emote.bingo';
    }
  }
  static configurationWebsite(key: string) {
    switch (this.env) {
      case 'local':
        return `http://localhost:3000/configure/${key}`;
      case 'prod':
        return `https://emote.bingo/configure/${key}`;
    }
  }
}
