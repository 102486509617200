export default {
  game: {
    leaderboard: 'Leaderboard',
    pause: 'Pause',
    start: 'Start',
    callingNumbers: 'Calling Numbers',
    namesGame: "{name}'s Bingo Game",
    countBoardsPurchased: '{count} Cards Are Ready To Play!',
    countBoardsPurchasedSingular: '{count} Card Is Ready To Play!',
    noPlayersYet: 'There are no players yet',
    startGame: 'Start Game',
    changeConfiguration: 'Change Configuration',
    manageGame: 'Manage Game',
    configureNewGame: 'Configure\nNew Game',
    offline: 'EmoteBingo is offline right now',
    onlyBroadcaster: 'Only the broadcaster can configure new EmoteBingo games.',
    tie: 'TIE!!',
    emoteBingo: 'EmoteBingo',
    startTieBreaker: 'Start Tie Breaker',
    startANewGame: 'Start A New Game!',
  },
  config: {
    configureYourGame: 'Configure your game',
    costOfCardHelper: 'The number of Twitch Bits per card',
    costOfCardLabel: 'Bits Per Card',
    costOfCardFree: 'Free',
    maxCardsPerViewerHelper: 'The maximum amount of cards that a single viewer can have',
    maxCardsPerViewerLabel: 'Max Cards Per Viewer',
    prizeHelper: 'The prize you will be required to reward to the winner',
    prizeLabel: 'Prize',
    prizeDescriptionLabel: 'Prize Description',
    gamePatternHelper: 'The pattern the viewer will have to complete to win',
    gamePatternLabel: 'Game Pattern',
    gamePatternSee: 'See',
    emotesHelper: 'The emotes for your bingo board',
    allowViewersToPurchase: 'Allow viewers to grab cards',
    emotesRandomize: 'Randomize',
    noConfigNeeded: 'EmoteBingo does not need any configuration!',
    headToStreamManager: 'Head to your Stream Manager to start a new game!',
  },
  viewer: {
    buyACard: 'Grab A Card',
    gamePrize: 'Game Prize',
    pattern: 'Pattern',
    prizeDisclaimer: 'Please note that the prize is an agreement between you and the streamer, not you and EmoteBingo.',
    buyAnotherCard: 'Grab Another Card',
    buy: 'Grab',
    cards: 'Cards ({count})',
    youLost: 'You lost this time',
    winner: 'Winner!!',
    sorry: 'Sorry!',
    youHaveWon: 'You Have Won',
    noActiveGames: 'There are no active\ngames right now!',
    askStreamerToStart: 'Ask the streamer to\nstart one!',
    checkAgain: 'Check again',
    gettingGameDetails: 'Getting game details...',
    watchingTieBreaker: 'A tie breaker is happening!',
    gameIsOver: 'The game is over!',
    pop: 'Pop',
  },
  generic: {
    errorHasOccurred: 'An error has occurred!!',
    navigateBackToDashboard: 'Please navigate back to your twitch dashboard and try again.',
    accountNotAuthorized:
      'Sorry, this account could not be authorized. Please navigate back to your twitch dashboard and try again. ',
  },
};
