import {action, observable} from 'mobx';

export class ChannelAuthStore {
  @observable jwt?: string;

  @action logout() {
    this.jwt = undefined;
  }
  @action setJWT(jwt: string | undefined): void {
    this.jwt = jwt;
    if (jwt) {
      localStorage.setItem('jwt', jwt);
    } else {
      localStorage.removeItem('jwt');
    }
  }
}

export const channelAuthStore = new ChannelAuthStore();
try {
  channelAuthStore.setJWT(localStorage.getItem('jwt') ?? undefined);
} catch (ex) {}
export type ChannelAuthStoreProps = {channelAuthStore: ChannelAuthStore};
