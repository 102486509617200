import React, {Fragment} from 'react';
import {CalledNumber, GameChannelClient, GameConfigurationEmotes} from '../dataServices/app.generated';
import {useWebStores} from '../store/webStores';
import {BingoNumber, ColumnToPosition, positionToColumn} from '../game/gameInfo';
import {Utils} from '../utils';
import {FC, useState} from 'react';
import {observer} from 'mobx-react';
import {BoxCard} from './boxCard';
import classNames from 'classnames';
import {Popover} from '@blueprintjs/core/lib/esm/components/popover/popover';
import {PromiseBoxCardButton} from './promiseBoxCardButton';
import {Tooltip} from '@blueprintjs/core/lib/esm/components/tooltip/tooltip';
import {AudioService} from '../audioService';
import {I} from '../i18n/translator';
import {AppConfig} from '../appConfig';
import {Animals} from '../game/animals';
import {handle400} from '../dataServices/baseClient';

export const EmoteList: FC<{
  emotes: GameConfigurationEmotes;
  setEmotes: (emotes: GameConfigurationEmotes) => void;
}> = observer(({emotes, setEmotes}) => {
  const {channelGameStore} = useWebStores();
  const [showEmoteChange, setShowEmoteChange] = useState<BingoNumber | undefined>();
  const currentEmotes = Object.values(emotes);
  const [selectedColumn, setSelectedColumn] = useState<'b' | 'i' | 'n' | 'g' | 'o' | undefined>();

  const availableEmotes: {code: string; id: string | number; url: string}[] = !AppConfig.animals
    ? [...(channelGameStore.channel?.emoteSet?.emotes ?? []), ...(channelGameStore.globalEmotes ?? [])].map((c) => ({
        code: c.code,
        id: c.id,
        url: getTwitchEmoteImageUrl(c.id, '3'),
      }))
    : [
        ...(channelGameStore.channel?.emoteSet?.emotes ?? []).map((c) => ({
          code: c.code,
          id: c.code,
          url: getTwitchEmoteImageUrl(c.id, '3'),
        })),
        ...Animals.map((a) => ({
          id: a.name,
          code: a.name,
          url: a.image,
        })),
      ];

  const emotesLeftInColumn = selectedColumn
    ? {
        b: availableEmotes.filter((a) => !ColumnToPosition.b.some((n) => emotes[n].id === a.id)),
        i: availableEmotes.filter((a) => !ColumnToPosition.i.some((n) => emotes[n].id === a.id)),
        n: availableEmotes.filter((a) => !ColumnToPosition.n.some((n) => emotes[n].id === a.id)),
        g: availableEmotes.filter((a) => !ColumnToPosition.g.some((n) => emotes[n].id === a.id)),
        o: availableEmotes.filter((a) => !ColumnToPosition.o.some((n) => emotes[n].id === a.id)),
      }
    : {b: [], i: [], n: [], g: [], o: []};

  return (
    <>
      <BoxCard
        elevation={2}
        theme={'success'}
        header={'EMOTES'}
        style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}
      >
        <div className={'emotes-board emotes-board-small'}>
          {Utils.range(1, 76).map((p) => {
            const position = p.toString() as BingoNumber;
            const column = positionToColumn(position);

            const currentEmote = emotes[position]!;
            return (
              <>
                {(position === '1' ||
                  position === '16' ||
                  position === '31' ||
                  position === '46' ||
                  position === '61') && (
                  <>
                    <div
                      key={column}
                      className={classNames(`header`, `column box-card box-card-05 box-card-button box-grey`)}
                    >
                      <span>{column}</span>
                    </div>
                  </>
                )}

                <div
                  key={position}
                  className={classNames(
                    {'box-blue': parseInt(position) % 2 === 1},
                    {'box-teal': parseInt(position) % 2 === 0},
                    {'box-gold': showEmoteChange === position},
                    `column box-card box-card-05 box-card-button box-grey`,
                    `number`
                  )}
                  style={{cursor: 'pointer'}}
                  onClick={(e) => {
                    AudioService.click();
                    setSelectedColumn(positionToColumn(position));
                    if (position === showEmoteChange) {
                      setShowEmoteChange(undefined);
                    } else {
                      setShowEmoteChange(position);
                    }
                  }}
                >
                  <Popover
                    canEscapeKeyClose
                    hasBackdrop
                    isOpen={showEmoteChange === position}
                    portalClassName={'no-portal-bg'}
                  >
                    <img alt={currentEmote.code} key={position} src={currentEmote.url} />
                    {showEmoteChange === position && (
                      <BoxCard
                        elevation={2}
                        theme={'grey'}
                        style={{
                          overflowY: 'scroll',
                          maxHeight: 150,
                          maxWidth: '30vw',
                          display: 'grid',
                          gridGap: '3px 3px',
                          gridTemplateColumns: 'repeat(7, 1fr)',
                          gridTemplateRows: 'repeat(100, 1fr)',
                        }}
                      >
                        {(AppConfig.animals
                          ? emotesLeftInColumn[selectedColumn!]
                          : availableEmotes.filter((a) => !currentEmotes.some((e) => e.id === a.id))
                        ).map((a, index) => (
                          <div
                            onClick={() => {
                              AudioService.click();
                              setEmotes({
                                ...emotes,
                                [showEmoteChange]: {
                                  code: a.code,
                                  id: a.id,
                                  url: a.url,
                                },
                              });
                              setShowEmoteChange(undefined);
                            }}
                            key={index}
                            className={classNames(
                              {'box-blue': index % 2 === 1},
                              {'box-teal': index % 2 === 0},
                              `column box-card box-card-05 box-card-button box-grey`,
                              `number`,
                              `center`
                            )}
                            style={{cursor: 'pointer'}}
                          >
                            <img style={{width: 30, height: 30}} alt={a.code} key={a.id} src={a.url} />
                          </div>
                        ))}
                      </BoxCard>
                    )}
                  </Popover>
                </div>
              </>
            );
          })}
        </div>
        <PromiseBoxCardButton
          style={{alignSelf: 'flex-end'}}
          className={'mt1'}
          theme={'warning'}
          onClick={async () => {
            const result = await GameChannelClient.randomizeEmotes({}, handle400);
            if (result) {
              channelGameStore.setGame(result.game);
              setEmotes(result.game.configuration.emotes);
            }
          }}
        >
          {I.get('config.emotesRandomize')}
        </PromiseBoxCardButton>
      </BoxCard>
    </>
  );
});

export const BingoEmoteList: FC<{
  calledNumbers: number[];
  emotes: GameConfigurationEmotes;
  isTie: boolean;
}> = observer(({emotes, calledNumbers, isTie}) => {
  const lastNumber = calledNumbers[calledNumbers.length - 1];
  return (
    <BoxCard
      elevation={3}
      theme={'grey'}
      style={{padding: 11}}
      innerClassName={classNames('emotes-board emotes-board-big', {'tie-emotes-board': isTie})}
    >
      {Utils.range(1, isTie ? 16 : 76).map((p) => {
        const position = p.toString() as BingoNumber;
        const column = positionToColumn(position);
        const lastColumn = lastNumber ? positionToColumn(lastNumber.toString() as BingoNumber) : undefined;
        const currentEmote = emotes[position];
        return (
          <Fragment key={p}>
            {(position === '1' || position === '16' || position === '31' || position === '46' || position === '61') && (
              <>
                <div
                  key={column}
                  className={classNames(
                    `header`,
                    `column box-card box-card-05 box-card-button ${lastColumn === column ? 'box-danger' : 'box-grey'}`
                  )}
                >
                  <span>{column}</span>
                </div>
              </>
            )}
            <div
              key={position}
              className={classNames(
                {'box-blue': parseInt(position) % 2 === 1},
                {'box-teal': parseInt(position) % 2 === 0},
                {'box-gold': calledNumbers.some((c) => `${c}` === position)},
                {'box-danger': lastNumber?.toString() === position},
                `column box-card box-card-05 box-card-button box-grey`,
                `number`
              )}
            >
              <Tooltip content={<h2>{currentEmote.code}</h2>}>
                <img alt={currentEmote.code} key={position} src={currentEmote.url} />
              </Tooltip>
            </div>
          </Fragment>
        );
      })}
    </BoxCard>
  );
});

function getTwitchEmoteImageUrl(id: number | string, size: '1' | '2' | '3') {
  return `https://static-cdn.jtvnw.net/emoticons/v1/${id}/${size}.0`;
}
