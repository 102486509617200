/* This file was generated by https://github.com/dested/serverless-client-builder */
/* tslint:disable */
/* eslint-disable */
import {ClientTransformOptions, ClientOptions, ClientSocketOptions, ControllerOptions} from './baseClient';
declare type ObjectId = string;

export class AnalyticsClient {
  static async emailPixel<TPromise = VoidResponse>(
    model: EmailPixelRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/analytics/email-pixel.gif?';

    return makeGetRequest(url, model, 'GET', handle);
  }

  static async event<TPromise = VoidResponse>(
    model: EventRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/analytics/event?';

    return makeRequest(url, model, 'POST', handle);
  }
}

export class ChannelClient {
  static async getGameRules<TPromise = GameRulesResponse>(
    model: VoidRequest,
    handle: {200?: (result: GameRulesResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/channel/game-rules?';

    return makeGetRequest(url, model, 'GET', handle);
  }

  static async start<TPromise = ChannelDataResponse>(
    model: ChannelRequest,
    handle: {
      200?: (result: ChannelDataResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/channel/start?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async config<TPromise = VoidResponse>(
    model: ChannelRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/channel/config?';

    return makeRequest(url, model, 'POST', handle);
  }
}

export class ChannelSocketClient {
  socket?: WebSocket;
  events?: ChannelSocketEvents;
  connect(events: ChannelSocketEvents) {
    this.events = events;
    this.socket = new WebSocket(ClientSocketOptions.baseUrl);
    this.socket.onmessage = message => {
      if (!this.events) return;
      const response = JSON.parse(message.data);
      switch (response.event) {
        case 'pop':
          this.events.onCardPop && this.events.onCardPop(response.data);
          break;

        case 'cardPurchased':
          this.events.onCardPurchased && this.events.onCardPurchased(response.data);
          break;
      }
    };
    this.socket.onclose = e => {
      this.events?.onDisconnect && this.events?.onDisconnect();
    };
    this.socket.onopen = () => {
      this.events?.onConnect && this.events?.onConnect();
    };
  }
  disconnect() {
    this.socket && this.socket.close();
  }
}

export interface ChannelSocketEvents {
  onCardPop: (req: PopResponse) => void;

  onCardPurchased: (req: CardPurchasedResponse) => void;

  onDisconnect: () => void;
  onConnect: () => void;
}

export class GameChannelClient {
  static async $meta<TPromise = MetaResponse>(
    model: VoidRequest,
    handle: {200?: (result: MetaResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/meta?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async callNumber<TPromise = CallNumberResponse>(
    model: CallNumberRequest,
    handle: {
      200?: (result: CallNumberResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/call-number?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async generateConfigurationKey<TPromise = GenerateConfigurationKeyResponse>(
    model: ChannelRequest,
    handle: {
      200?: (result: GenerateConfigurationKeyResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/generate-configuration-key?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async getAllGames<TPromise = GetGamesResponse>(
    model: GetGamesRequest,
    handle: {
      200?: (result: GetGamesResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/games?';

    return makeGetRequest(url, model, 'GET', handle);
  }

  static async getBoards<TPromise = GetGameBoardsResponse>(
    model: GetGameBoardsRequest,
    handle: {
      200?: (result: GetGameBoardsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/boards?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async getGameDetails<TPromise = GetGameDetailsResponse>(
    model: GetGameDetailsRequest,
    handle: {
      200?: (result: GetGameDetailsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/details?';

    return makeGetRequest(url, model, 'GET', handle);
  }

  static async getGlobalEmotes<TPromise = GlobalEmotesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GlobalEmotesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/global-emotes?';

    return makeGetRequest(url, model, 'GET', handle);
  }

  static async makeBoardsBuyable<TPromise = GameResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GameResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/make-boards-buyable?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async randomizeEmotes<TPromise = GameResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GameResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/randomize-emotes?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async needHelp<TPromise = VoidResponse>(
    model: NeedHelpRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/need-help?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async reauthorizeGame<TPromise = ReauthorizeGameResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: ReauthorizeGameResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/reauthorize-game?';

    return makeGetRequest(url, model, 'GET', handle);
  }

  static async setBackToConfiguration<TPromise = GameResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GameResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/set-back-to-configuration?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async startFromConfigurationKey<TPromise = StartFromConfigurationKeyResponse>(
    model: StartFromConfigurationKeyRequest,
    handle: {
      200?: (result: StartFromConfigurationKeyResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/start-from-configuration-key?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async startGame<TPromise = StartGameResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: StartGameResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/start-game?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async startNewGame<TPromise = GenerateConfigurationKeyResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GenerateConfigurationKeyResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/start-new-game?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async updateGameConfiguration<TPromise = GameResponse>(
    model: UpdateGameConfigurationRequest,
    handle: {
      200?: (result: GameResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-channel/update-game-configuration?';

    return makeRequest(url, model, 'POST', handle);
  }
}

export class GameViewerClient {
  static async buyCard<TPromise = BuyCardResponse>(
    model: BuyCardRequest,
    handle: {
      200?: (result: BuyCardResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-viewer/buy-card?';

    return makeRequest(url, model, 'POST', handle);
  }

  static async getGameDetails<TPromise = GameViewerResponse>(
    model: ViewerGameDetailsRequest,
    handle: {
      200?: (result: GameViewerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-viewer/details?';

    return makeGetRequest(url, model, 'GET', handle);
  }

  static async getGameDetailsLight<TPromise = GameViewerLightResponse>(
    model: GetGameDetailsLightRequest,
    handle: {
      200?: (result: GameViewerLightResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-viewer/details-light?';

    return makeGetRequest(url, model, 'GET', handle);
  }

  static async pop<TPromise = VoidResponse>(
    model: VoidRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/game-viewer/pop?';

    return makeRequest(url, model, 'POST', handle);
  }
}

export class ViewerClient {
  static async start<TPromise = GetViewerDetailsResponse>(
    model: GetViewerDetailsRequest,
    handle: {
      200?: (result: GetViewerDetailsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    let url = ClientOptions.baseUrl + '/viewer/viewer-details?';

    return makeGetRequest(url, model, 'GET', handle);
  }
}

export interface EmailPixelRequest {
  t: string;
}

export interface VoidResponse {}

export interface EventRequest {
  eventName: string;
  metaData: string;
  time: string;
}

export interface VoidRequest {}

export interface GameRulesResponse {
  allowFree: boolean;
}

export interface ChannelRequest {}

export interface ChannelDataResponse {
  activeGame?: HttpGameModel;
  channel: HttpChannelModel;
}

export type HttpGameModel = {
  active: boolean;
  configuration: GameConfiguration;
  dates: GameModelDates;
  gameId: string;
  numbersCalled: number[];
  runOffNumbersCalled: number[];
  runOffWinningCards: WinningCard[];
  state: GameState;
  winners: {viewerId: string}[];
  winningCards: WinningCard[];
};

export type GameConfiguration = {
  costOfCard: GameCardCost;
  firstCardFree: boolean;
  emotes: GameConfigurationEmotes;
  gamePatternName: GameConfigurationGamePatternName;
  gamePatterns: GameConfigurationGamePattern[];
  maxNumberOfCardsPerViewer: number;
  prize: GameConfigurationPrize;
};

export type GameCardCost = '0' | '25' | '50' | '75' | '100' | '150' | '200' | '250';

export interface GameConfigurationEmotes {
  1: GameConfigurationEmote;
  2: GameConfigurationEmote;
  3: GameConfigurationEmote;
  4: GameConfigurationEmote;
  5: GameConfigurationEmote;
  6: GameConfigurationEmote;
  7: GameConfigurationEmote;
  8: GameConfigurationEmote;
  9: GameConfigurationEmote;
  10: GameConfigurationEmote;
  11: GameConfigurationEmote;
  12: GameConfigurationEmote;
  13: GameConfigurationEmote;
  14: GameConfigurationEmote;
  15: GameConfigurationEmote;
  16: GameConfigurationEmote;
  17: GameConfigurationEmote;
  18: GameConfigurationEmote;
  19: GameConfigurationEmote;
  20: GameConfigurationEmote;
  21: GameConfigurationEmote;
  22: GameConfigurationEmote;
  23: GameConfigurationEmote;
  24: GameConfigurationEmote;
  25: GameConfigurationEmote;
  26: GameConfigurationEmote;
  27: GameConfigurationEmote;
  28: GameConfigurationEmote;
  29: GameConfigurationEmote;
  30: GameConfigurationEmote;
  31: GameConfigurationEmote;
  32: GameConfigurationEmote;
  33: GameConfigurationEmote;
  34: GameConfigurationEmote;
  35: GameConfigurationEmote;
  36: GameConfigurationEmote;
  37: GameConfigurationEmote;
  38: GameConfigurationEmote;
  39: GameConfigurationEmote;
  40: GameConfigurationEmote;
  41: GameConfigurationEmote;
  42: GameConfigurationEmote;
  43: GameConfigurationEmote;
  44: GameConfigurationEmote;
  45: GameConfigurationEmote;
  46: GameConfigurationEmote;
  47: GameConfigurationEmote;
  48: GameConfigurationEmote;
  49: GameConfigurationEmote;
  50: GameConfigurationEmote;
  51: GameConfigurationEmote;
  52: GameConfigurationEmote;
  53: GameConfigurationEmote;
  54: GameConfigurationEmote;
  55: GameConfigurationEmote;
  56: GameConfigurationEmote;
  57: GameConfigurationEmote;
  58: GameConfigurationEmote;
  59: GameConfigurationEmote;
  60: GameConfigurationEmote;
  61: GameConfigurationEmote;
  62: GameConfigurationEmote;
  63: GameConfigurationEmote;
  64: GameConfigurationEmote;
  65: GameConfigurationEmote;
  66: GameConfigurationEmote;
  67: GameConfigurationEmote;
  68: GameConfigurationEmote;
  69: GameConfigurationEmote;
  70: GameConfigurationEmote;
  71: GameConfigurationEmote;
  72: GameConfigurationEmote;
  73: GameConfigurationEmote;
  74: GameConfigurationEmote;
  75: GameConfigurationEmote;

  freeSpace: GameConfigurationEmote;
}

export type GameConfigurationEmote = {
  code: string;
  id: string;
  url: string;
};

export type GameConfigurationGamePatternName = 'bingo' | 'big-square' | 'small-square' | 'full-board' | 'turtle';

export type GameConfigurationGamePattern =
  | 'line-b'
  | 'line-i'
  | 'line-n'
  | 'line-g'
  | 'line-o'
  | 'line-1'
  | 'line-2'
  | 'line-3'
  | 'line-4'
  | 'line-5'
  | 'line-left-diag'
  | 'line-right-diag'
  | 'big-square'
  | 'small-square'
  | 'full-board'
  | 'turtle-up'
  | 'turtle-down'
  | 'tie';

export type GameConfigurationPrize =
  | 'vip-for-day'
  | 'vip-for-week'
  | 'vip-for-month'
  | 'dance'
  | 'next-game'
  | 'play-youtube'
  | 'pick-song'
  | 'shout-out';

export type GameModelDates = {
  boardsOpen?: Date;
  created: Date;
  gameStarted?: Date;
  preProcessing?: Date;
  gameWon?: Date;
  runOffTie?: Date;
};

export type WinningCard = {viewerAvatar: string; viewerName: string};

export type GameState =
  | 'created'
  | 'configuring'
  | 'boards-buyable'
  | 'pre-processing'
  | 'started'
  | 'winner-no-tie'
  | 'run-off-tie';

export type HttpChannelModel = {
  channelDetails: TwitchChannelDetails;
  channelId: string;
  emoteSet?: TwitchEmoteResult;
  twitchChannelId: string;
};

export type TwitchChannelDetails = {
  _id: number;
  broadcaster_language: string;
  created_at: string;
  display_name: string;
  followers: number;
  game: string;
  language: string;
  logo: string;
  mature: boolean;
  name: string;
  partner: boolean;
  profile_banner: boolean;
  profile_banner_background_color: string;
  status: string;
  updated_at: string;
  url: string;
  video_banner: string;
  views: number;
};

export type TwitchEmoteResult = {
  base_set_id: string;
  bits_badges: {[numberOfBits in TwitchBitsBadgeCount]: TwitchImage};
  broadcaster_type: string;
  channel_id: string;
  channel_name: string;
  cheermotes: {[numberOfBits in TwitchBitsCheerCount]: {[twitchCheermoteSize: string]: string}};
  display_name: string;
  emotes: {code: string; emoticon_set: number; id: number}[];
  generated_at: Date;
  plans: {[price: string]: string};
  subscriber_badges: {[months in TwitchSubscriberBadgeCount]: TwitchImage};
};

export type TwitchBitsBadgeCount =
  | '1000'
  | '5000'
  | '10000'
  | '25000'
  | '50000'
  | '75000'
  | '100000'
  | '200000'
  | '300000'
  | '400000'
  | '500000'
  | '600000'
  | '700000'
  | '800000'
  | '900000'
  | '1000000';

export type TwitchImage = {
  image_url_1x: string;
  image_url_2x: string;
  image_url_4x: string;
  title: string;
};

export type TwitchBitsCheerCount = '1' | '100' | '1000' | '5000' | '10000';

export type TwitchSubscriberBadgeCount = '0' | '3' | '6' | '12' | '24' | '36' | '48' | '60' | '72' | '84' | '96';

export interface PopResponse {}

export interface CardPurchasedResponse {
  cardsPurchased: number;
}

export interface MetaResponse {
  emoteBingoBroadcasts: EmoteBingoBroadcasts;
}

export type HttpGameLightModel = {
  active: boolean;
  gameId: string;
  numbersCalled: number[];
  runOffNumbersCalled: number[];
  runOffWinningCards: LightWinningCard[];
  state: GameState;
  winners: {viewerId: string}[];
  winningCards: LightWinningCard[];
};

export type LightWinningCard = {viewerId: string};

export type EmoteBingoBroadcasts =
  | {gameId: string; type: 'gameConfiguring'}
  | {gameId: string; type: 'boardsAreBuyable'}
  | {gameId: string; type: 'setBackToConfiguring'}
  | {gameId: string; type: 'gameStarted'}
  | {gameId: string; lightGameDetails: HttpGameLightModel; type: 'numberCalled'}
  | {gameDetails: HttpGameLightModel; gameId: string; type: 'winnerTie'}
  | {gameDetails: HttpGameLightModel; gameId: string; type: 'winner'};

export interface CallNumberRequest {}

export interface CallNumberResponse {
  boardStatistics: BoardStatistic[];
  gameState: GameState;
  numberCalled: CalledNumber;
  requiresRunOff: boolean;
  runOffWinningCards: {boardId: string; viewerAvatar: string; viewerId: string; viewerName: string}[];
  winningCards: {boardId: string; viewerAvatar: string; viewerId: string; viewerName: string}[];
}

export type BoardStatistic = {boardId: string; numberLeft: number; viewerAvatar: string; viewerName: string};

export type CalledNumber = {
  column: BoardColumn;
  date: Date;
  number: number;
};

export type BoardColumn = 'b' | 'i' | 'n' | 'g' | 'o';

export interface ChannelRequest {}

export interface GenerateConfigurationKeyResponse {
  configurationKey: string;
}

export interface GetGamesRequest {
  page: number;
}

export interface GetGamesResponse {
  games: {active: boolean; boardsPurchased: number; dates: GameModelDates; state: GameState}[];
  totalResults: number;
}

export interface GetGameBoardsRequest {
  boardIds: string[];
}

export interface GetGameBoardsResponse {
  boards: HttpViewerGameBoardModel[];
}

export type HttpViewerGameBoardModel = {
  channelId: string;
  gameId: string;
  numbers: BoardNumber[];
  tieBoard: boolean;
  viewerId: string;
  viewerGameBoardModelId: string;
};

export type BoardNumber = {
  column: BoardColumn;
  number: number;
  position: BoardPosition;
};

export type BoardPosition =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25';

export interface GetGameDetailsRequest {
  gameId: string;
}

export interface GetGameDetailsResponse {
  boardsPurchased: number;
  game: HttpGameModel;
}

export interface GlobalEmotesResponse {
  emotes: GameConfigurationEmote[];
}

export interface GameResponse {
  game: HttpGameModel;
}

export interface NeedHelpRequest {
  message: string;
}

export interface ReauthorizeGameResponse {
  boardsPurchased: number;
  boardStatistics: BoardStatistic[];
  channel: HttpChannelModel;
  game: HttpGameModel;
  jwt: string;
}

export interface StartFromConfigurationKeyRequest {
  configurationKey: string;
}

export interface StartFromConfigurationKeyResponse {
  jwt: string;
}

export interface StartGameResponse {
  boardStatistics: BoardStatistic[];
  game: HttpGameModel;
}

export interface UpdateGameConfigurationRequest {
  costOfCard?: GameCardCost;
  firstCardFree?: boolean;
  emotes?: GameConfigurationEmotes;
  gamePatternName?: GameConfigurationGamePatternName;
  maxNumberOfCardsPerViewer?: number;
  prize?: GameConfigurationPrize;
}

export interface BuyCardRequest {
  transactionReceipt: string;
}

export interface BuyCardResponse {
  board: HttpViewerGameBoardModel;
}

export interface ViewerGameDetailsRequest {
  gameId?: string;
}

export interface GameViewerResponse {
  game?: HttpGameModel;
  myBoards: HttpViewerGameBoardModel[];
  noActiveGames: boolean;
}

export interface GetGameDetailsLightRequest {
  gameId: string;
}

export interface GameViewerLightResponse {
  game: HttpGameLightModel;
}

export interface GetViewerDetailsRequest {}

export interface GetViewerDetailsResponse {
  viewer: HttpViewerModel;
}

export type HttpViewerModel = {
  twitchViewerId: string;
  twitchViewerName: string;
  viewerId: string;
};

async function handleResponse(responseText: string, status: number, handle: any) {
  try {
    if (handle[status]) {
      const val = responseText === '' ? null : JSON.parse(responseText);
      await handle[status](val);
      return undefined;
    } else {
      switch (status) {
        case 200: {
          return JSON.parse(responseText);
        }
        case 401: {
          ClientOptions.handleUnauthorized(responseText);
          break;
        }
        case 500: {
          const body = JSON.parse(responseText);
          ClientOptions.handleError(body.error || responseText);
          break;
        }
      }
    }
  } catch (ex) {
    if (!handle[500]) {
      ClientOptions.handleError(ex.toString());
    } else {
      await handle[500](ex.toString());
    }
  }
}
async function makeRequest(url: string, model: any, method: string, handle: any) {
  try {
    const options = {
      method,
    } as RequestInit;

    options.body = JSON.stringify(model);

    const response = await fetch(url, ClientTransformOptions(options));
    const status = response.status;
    const responseText = await response.text();
    return handleResponse(responseText, status, handle);
  } catch (ex) {
    return handleResponse(ex.toString(), 500, handle);
  }
}

async function makeGetRequest(url: string, model: any, method: string, handle: any) {
  try {
    const options = {
      method,
    } as RequestInit;
    url += Object.keys(model)
      .filter(key => !!(model as any)[key])
      .map(key => `${key}=${encodeURIComponent((model as any)[key])}`)
      .join('&');

    const response = await fetch(url, ClientTransformOptions(options));
    const status = response.status;
    const responseText = await response.text();
    return handleResponse(responseText, status, handle);
  } catch (ex) {
    return handleResponse(ex.toString(), 500, handle);
  }
}
