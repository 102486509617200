import React from 'react';
import ReactDOM from 'react-dom';
import {webStores} from '@common/store/webStores';
import {BrowserRouter} from 'react-router-dom';
import {Route} from 'react-router';
import {observer, Provider} from 'mobx-react';
import {Home} from './screens/home/home';
import {ConfigureGame} from './screens/configureGame/configureGame';
import {AudioService} from '@common/audioService';
import {GameWrapper} from './screens/game/gameWrapper';
import {BottomMessage} from '@common/components/bottomMessage';

AudioService.start();

const App = observer(() => {
  return (
    <>
      <BrowserRouter>
        <>
          <Route exact path="/" component={Home} />
          <Route exact path="/configure/:key" component={ConfigureGame} />
          <Route exact path="/game" component={GameWrapper} />
        </>
      </BrowserRouter>
      <BottomMessage message={webStores.uiStore.message} setMessage={(m) => webStores.uiStore.setMessage(m)} />
    </>
  );
});

ReactDOM.render(
  <React.StrictMode>
    <Provider {...webStores}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
