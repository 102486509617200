import React, {FC, useCallback} from 'react';
import {observer} from 'mobx-react';
import {useWebStores} from '@common/store/webStores';
import {Controller, useForm} from 'react-hook-form';
import {
  GameCardCost,
  GameChannelClient,
  GameConfigurationEmotes,
  GameConfigurationGamePatternName,
  GameConfigurationPrize,
} from '@common/dataServices/app.generated';
import {DebounceUtils} from '@common/debounceUtils';
import {
  GameCardCosts,
  GamePatternNames,
  Prizes,
  GamePatternNameToPrettyName,
  PrizeToPrettyPrize,
} from '@common/game/gameInfo';
import {GamePatternList} from '@common/components/gamePatternList';
import {EmoteList} from '@common/components/emoteList';
import {FormGroup, HTMLSelect, InputGroup, Popover, Switch} from '@blueprintjs/core';
import {Box} from '@common/components/box';
import {BoxCard, BoxCardButton} from '@common/components/boxCard';
import {Button} from '@blueprintjs/core/lib/esm/components/button/buttons';
import {Utils} from '@common/utils';
import {AudioService} from '@common/audioService';
import {I} from '@common/i18n/translator';
import {handle400} from '@common/dataServices/baseClient';
import logo from '@common/assets/logo-wide.png';

export const ConfigurationView: FC = observer(() => {
  const {channelGameStore} = useWebStores();

  const onMakeBoardsBuyable = useCallback(async () => {
    AudioService.click();
    await Utils.timeout(1500);
    const result = await GameChannelClient.makeBoardsBuyable({}, handle400);
    if (result) {
      channelGameStore.setGame(result.game);
    }
  }, [channelGameStore]);

  const {register, formState, control, handleSubmit, getValues, watch} = useForm<{
    costOfCard: GameCardCost;
    emotes: GameConfigurationEmotes;
    firstCardFree: boolean;
    gamePatternName: GameConfigurationGamePatternName;
    maxNumberOfCardsPerViewer: number;
    prize: GameConfigurationPrize;
    prizeOther?: string;
  }>({
    mode: 'onSubmit',
    defaultValues: channelGameStore.game!.configuration,
  });

  const {prize, prizeOther, gamePatternName, firstCardFree} = watch();

  const onFormUpdated = useCallback(() => {
    DebounceUtils.debounce('form-updated', 1000, async () => {
      const values = getValues();
      await GameChannelClient.updateGameConfiguration(
        {
          gamePatternName: values.gamePatternName,
          costOfCard: values.costOfCard,
          firstCardFree: values.firstCardFree,
          emotes: values.emotes,
          maxNumberOfCardsPerViewer: parseInt((values.maxNumberOfCardsPerViewer as unknown) as string),
          prize: values.prize,
        },
        {
          200: ({game}) => {
            channelGameStore.setGame(game);
          },
          ...handle400,
        }
      );
    });
  }, []);

  const validateForm = (): boolean => {
    return true;
  };

  let gameCardCosts = GameCardCosts;
  if (!channelGameStore.gameRules?.allowFree) {
    gameCardCosts = gameCardCosts.filter((a) => a !== '0');
  }
  return (
    <>
      <img src={logo} className={'game-logo'} />
      <Box justify align className={'m1'}>
        <form className={'form'} onSubmit={handleSubmit(onMakeBoardsBuyable)}>
          <BoxCard theme={'grey'} elevation={2} header={I.get('config.configureYourGame')} className={'bp3-dark'}>
            <Box col>
              <Box>
                <Box>
                  <FormGroup
                    helperText={I.get('config.costOfCardHelper')}
                    label={I.get('config.costOfCardLabel')}
                    labelFor="costOfCard"
                  >
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <HTMLSelect
                        onChange={onFormUpdated}
                        name="costOfCard"
                        elementRef={register({required: true})}
                        large
                      >
                        {gameCardCosts.map((c) => (
                          <option key={c} value={c}>
                            {c === '0' ? I.get('config.costOfCardFree') : `${c} Bits`}
                          </option>
                        ))}
                      </HTMLSelect>
                      <div style={{width: '5px'}} />
                      <Switch
                        name={'firstCardFree'}
                        inputRef={register({required: true})}
                        label="No Bits For The First Card"
                        onChange={onFormUpdated}
                      />
                    </div>
                  </FormGroup>
                </Box>
                <Box>
                  <FormGroup
                    helperText={I.get('config.maxCardsPerViewerHelper')}
                    label={I.get('config.maxCardsPerViewerLabel')}
                    labelFor="maxNumberOfCardsPerViewer"
                  >
                    <HTMLSelect
                      onChange={onFormUpdated}
                      name="maxNumberOfCardsPerViewer"
                      elementRef={register({required: true})}
                      large
                    >
                      {[1, 2, 3, 4, 5].map((c) => (
                        <option key={c} value={c}>
                          {c}
                        </option>
                      ))}
                    </HTMLSelect>
                  </FormGroup>
                </Box>
              </Box>
              <Box>
                <Box col>
                  <FormGroup
                    helperText={I.get('config.prizeHelper')}
                    label={I.get('config.prizeLabel')}
                    labelFor="prize"
                  >
                    <Box>
                      <HTMLSelect onChange={onFormUpdated} name="prize" elementRef={register({required: true})} large>
                        {Prizes.map((c) => (
                          <option key={c} value={c}>
                            {PrizeToPrettyPrize(c)}
                          </option>
                        ))}
                      </HTMLSelect>
                    </Box>
                  </FormGroup>
                </Box>
                <Box col>
                  <FormGroup
                    helperText={I.get('config.gamePatternHelper')}
                    label={I.get('config.gamePatternLabel')}
                    labelFor="gamePatternName"
                  >
                    <HTMLSelect
                      onChange={onFormUpdated}
                      name="gamePatternName"
                      elementRef={register({required: true})}
                      large
                    >
                      {GamePatternNames.map((c) => (
                        <option key={c} value={c}>
                          {GamePatternNameToPrettyName(c)}
                        </option>
                      ))}
                    </HTMLSelect>
                    <Popover interactionKind={'click'}>
                      <Button className={'mh2'}>{I.get('config.gamePatternSee')}</Button>
                      <Box className={'p1'}>
                        <GamePatternList pattern={gamePatternName} showAverage={true} />
                      </Box>
                    </Popover>
                  </FormGroup>
                </Box>
                {/*<Box></Box>
                <Box>
                  <FormGroup
                    helperText={
                      <>
                        The number of winners allowed
                        <br />
                        (you will have to reward this many prizes)
                      </>
                    }
                    label="Number Of Winners"
                    labelFor="numberOfWinners"
                  >
                    <HTMLSelect
                      onChange={onFormUpdated}
                      name="numberOfWinners"
                      elementRef={register({required: true})}
                      large
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((c) => (
                        <option key={c} value={c}>
                          {c}
                        </option>
                      ))}
                    </HTMLSelect>
                  </FormGroup>
                </Box>*/}
              </Box>
            </Box>
          </BoxCard>
          <div style={{textAlign: 'center', paddingTop: 10}}>
            <FormGroup helperText={I.get('config.emotesHelper')} label="">
              <Controller
                control={control}
                name="emotes"
                render={({onChange, value}) => {
                  return (
                    <EmoteList
                      emotes={value}
                      setEmotes={(newEmotes) => {
                        onChange(newEmotes);
                        onFormUpdated();
                      }}
                    />
                  );
                }}
              />
            </FormGroup>
          </div>

          <Box style={{justifyContent: 'flex-end'}}>
            <BoxCardButton
              theme={'success'}
              type={'submit'}
              loading={formState.isSubmitting}
              disabled={!validateForm()}
            >
              {I.get('config.allowViewersToPurchase')}
            </BoxCardButton>
          </Box>
        </form>
      </Box>
    </>
  );
});
