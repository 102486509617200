import click from './assets/audio/click3.ogg';
import newBall from './assets/audio/switch27.ogg';
import win from './assets/audio/jingles_PIZZA10.ogg';
import game from './assets/audio/polka-cut.ogg';

export class AudioService {
  static gameAudioSpeed: 'slow' | 'fast' = 'slow';
  static muted: boolean;

  static playingGameAudio: boolean = false;

  static sounds: {
    click: HTMLAudioElement;
    game: HTMLAudioElement;
    newBall: HTMLAudioElement;
    win: HTMLAudioElement;
  };

  static click() {
    if (!this.muted) {
      this.sounds.click?.play();
    }
  }
  static game() {
    if (!this.muted) {
      if (this.sounds.game) {
        this.sounds.game.addEventListener('timeupdate', function () {
          const buffer = 0.05;
          if (this.currentTime > this.duration - buffer) {
            this.currentTime = 0;
            this.play();
          }
        });
        this.sounds.game.play();
      }
    }
  }
  static newBall() {
    if (!this.muted) {
      this.sounds.newBall?.play();
    }
  }

  static playGameAudio() {
    this.playingGameAudio = true;
    this.game();
  }

  static setGameAudioSpeed(speed: 'slow' | 'fast') {
    this.gameAudioSpeed = speed;
    switch (this.gameAudioSpeed) {
      case 'slow':
        this.sounds.game.playbackRate = 1;
        break;
      case 'fast':
        this.sounds.game.playbackRate = 1.3;
        break;
    }
  }

  static setMuted(muted: boolean) {
    this.muted = muted;
    if (muted) {
      this.sounds.game.pause();
    } else {
      if (this.playingGameAudio) {
        this.sounds.game.play();
      }
    }
  }
  static start() {
    this.sounds = {
      click: new Audio(click),
      newBall: new Audio(newBall),
      win: new Audio(win),
      game: new Audio(game),
    };
  }
  static stopGameAudio() {
    this.playingGameAudio = false;
    this.sounds.game.pause();
  }
  static win() {
    if (!this.muted) {
      this.sounds.win?.play();
    }
  }
}
