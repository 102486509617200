import {GameConfigurationGamePatternName} from './gamePatterns';
import {objectSafeKeys, unreachable, Utils} from '../utils';
import {GameConfigurationEmotes} from '../dataServices/app.generated';

export type GameCardCost = '0' | '25' | '50' | '75' | '100' | '150' | '200' | '250';
export const GameCardCosts: GameCardCost[] = ['0', '25', '50', '75', '100', '150', '200', '250'];

export type GameConfigurationPrize =
  | 'vip-for-day'
  | 'vip-for-week'
  | 'vip-for-month'
  | 'dance'
  | 'next-game'
  | 'play-youtube'
  | 'pick-song'
  | 'shout-out';

export const Prizes: GameConfigurationPrize[] = [
  'shout-out',
  'pick-song',
  'play-youtube',
  'dance',
  'next-game',
  'vip-for-day',
  'vip-for-week',
  'vip-for-month',
];

export const GamePatternNames: GameConfigurationGamePatternName[] = [
  'bingo',
  'big-square',
  'small-square',
  'full-board',
  'turtle',
];

export function GamePatternNameToPrettyName(name: GameConfigurationGamePatternName) {
  switch (name) {
    case 'bingo':
      return 'Classic Bingo';
    case 'big-square':
      return 'Big Square';
    case 'small-square':
      return 'Small Square';
    case 'full-board':
      return 'Full Board';
    case 'turtle':
      return 'Turtle';
    default:
      unreachable(name);
  }
}
export function PrizeToPrettyPrize(name: GameConfigurationPrize) {
  switch (name) {
    case 'play-youtube':
      return 'Pick the next\nYoutube video!';
    case 'pick-song':
      return 'Pick the next\nSong!';
    case 'dance':
      return 'Pick the next\nDance!';
    case 'next-game':
      return 'Pick the next\nGame!';
    case 'shout-out':
      return 'Shout out\nthe winner!';
    case 'vip-for-day':
      return 'Vip for the day!';
    case 'vip-for-week':
      return 'Vip for the week!';
    case 'vip-for-month':
      return 'Vip for the month!';
    default:
      unreachable(name);
  }
}

const prefixes: {[gameId: string]: string} = {};
export function determinePrefix(gameId: string, emotes: GameConfigurationEmotes): string {
  if (prefixes[gameId] !== undefined) {
    return prefixes[gameId];
  }
  const codes = Utils.groupBy(
    objectSafeKeys(emotes)
      .map((a) => emotes[a].code)
      .map((a) => {
        const j = a.match(/^([a-z])*/)?.[0];
        if (j) {
          return j.length === a.length ? undefined : j;
        }
        return undefined;
      })
      .filter((a) => a),
    (a) => a
  );
  const sortedCodes = codes.sort((a, b) => b.items.length - a.items.length);
  const code = sortedCodes[0]?.key ?? '';
  prefixes[gameId] = code;
  return code;
}

export function positionToColumn(position: BingoNumber): 'b' | 'i' | 'n' | 'g' | 'o' {
  switch (position) {
    case '1':
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
    case '10':
    case '11':
    case '12':
    case '13':
    case '14':
    case '15':
      return 'b';
    case '16':
    case '17':
    case '18':
    case '19':
    case '20':
    case '21':
    case '22':
    case '23':
    case '24':
    case '25':
    case '26':
    case '27':
    case '28':
    case '29':
    case '30':
      return 'i';
    case '31':
    case '32':
    case '33':
    case '34':
    case '35':
    case '36':
    case '37':
    case '38':
    case '39':
    case '40':
    case '41':
    case '42':
    case '43':
    case '44':
    case '45':
      return 'n';
    case '46':
    case '47':
    case '48':
    case '49':
    case '50':
    case '51':
    case '52':
    case '53':
    case '54':
    case '55':
    case '56':
    case '57':
    case '58':
    case '59':
    case '60':
      return 'g';
    case '61':
    case '62':
    case '63':
    case '64':
    case '65':
    case '66':
    case '67':
    case '68':
    case '69':
    case '70':
    case '71':
    case '72':
    case '73':
    case '74':
    case '75':
      return 'o';
  }
}

export const ColumnToPosition: {
  b: BingoNumber[];
  g: BingoNumber[];
  i: BingoNumber[];
  n: BingoNumber[];
  o: BingoNumber[];
} = {
  b: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
  i: ['16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
  n: ['31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45'],
  g: ['46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60'],
  o: ['61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75'],
};

export type BingoNumber =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31'
  | '32'
  | '33'
  | '34'
  | '35'
  | '36'
  | '37'
  | '38'
  | '39'
  | '40'
  | '41'
  | '42'
  | '43'
  | '44'
  | '45'
  | '46'
  | '47'
  | '48'
  | '49'
  | '50'
  | '51'
  | '52'
  | '53'
  | '54'
  | '55'
  | '56'
  | '57'
  | '58'
  | '59'
  | '60'
  | '61'
  | '62'
  | '63'
  | '64'
  | '65'
  | '66'
  | '67'
  | '68'
  | '69'
  | '70'
  | '71'
  | '72'
  | '73'
  | '74'
  | '75';
