import React, {FC, useCallback, useState} from 'react';
import {observer} from 'mobx-react';
import {Game} from './game';
import {Button, FormGroup, HTMLSelect, TextArea} from '@blueprintjs/core';
import {AudioService} from '@common/audioService';
import {BoxCard} from '@common/components/boxCard';
import {I} from '@common/i18n/translator';
import {Box} from '@common/components/box';
import {PromiseBoxCardButton} from '@common/components/promiseBoxCardButton';
import {AppToaster} from '@common/index';
import {GameChannelClient} from '@common/dataServices/app.generated';
import {handle400} from '@common/dataServices/baseClient';

export const GameWrapper: FC = observer(() => {
  const [help, setHelp] = useState(false);
  const [helpMessage, setHelpMessage] = useState('');
  const onSendMessage = useCallback(async () => {
    AppToaster.show({message: 'Your message has been sent!', intent: 'success'});
    await GameChannelClient.needHelp({message: helpMessage}, handle400);
    setHelpMessage('');
    setHelp(false);
  }, [helpMessage]);

  return (
    <>
      <Game />
      <div style={{position: 'absolute', right: 10, bottom: 10}}>
        {help && (
          <BoxCard elevation={1} theme={'warning'} header={'HELP!'} className={'wrap'}>
            Is there a problem? Send us a message and we will{'\n'}try to help immediately!
            <Box col>
              <TextArea
                autoFocus
                className={'mv1'}
                style={{width: '100%'}}
                onChange={(e) => setHelpMessage(e.target.value)}
                name="helpMessage"
                large
                value={helpMessage}
              />
              <PromiseBoxCardButton outerStyle={{alignSelf: 'flex-end'}} onClick={onSendMessage} theme={'success'}>
                Send!!
              </PromiseBoxCardButton>
            </Box>
          </BoxCard>
        )}
        <Box style={{justifyContent: 'flex-end'}}>
          <Button
            onClick={() => AudioService.setMuted(!AudioService.muted)}
            large
            icon={'music'}
            minimal
            style={{fontSize: '2rem'}}
          />
          <Button onClick={() => setHelp(!help)} large minimal style={{fontSize: '2rem'}}>
            Help
          </Button>
        </Box>
      </div>
    </>
  );
});
