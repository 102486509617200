import {CalledBallView} from '@common/components/calledBallView';
import {BingoEmoteList} from '@common/components/emoteList';
import {Utils} from '@common/utils';
import {BoardStatisticView} from './boardStatisticView';
import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useWebStores} from '@common/store/webStores';
import {observer} from 'mobx-react';
import {Box} from '@common/components/box';
import {BoxCardList, BoxCardButton, BoxCard, BoxSquare} from '@common/components/boxCard';
import {BingoBoard} from '@common/components/bingoBoard';
import {Spinner} from '@blueprintjs/core';
import {PrizeToPrettyPrize} from '@common/game/gameInfo';
import {PromiseBoxCardButton} from '@common/components/promiseBoxCardButton';
import {useHistory} from 'react-router';
import {AudioService} from '@common/audioService';
import {I} from '@common/i18n/translator';
import {GameChannelClient} from '@common/dataServices/app.generated';
import {handle400} from '@common/dataServices/baseClient';
import logo from '@common/assets/logo-wide.png';

export const LiveGame: FC<{}> = observer(({}) => {
  useEffect(() => {
    AudioService.playGameAudio();
    return () => {
      AudioService.stopGameAudio();
    };
  }, []);

  const {channelGameStore} = useWebStores();
  const history = useHistory();

  const killAutoCalling = useRef(false);
  const [inAutocall, setInAutocall] = useState(false);
  const [currentlyAutocalling, setCurrentlyAutocalling] = useState(false);

  const onCallNumber = useCallback(async () => {
    if (currentlyAutocalling) {
      setCurrentlyAutocalling(false);
      killAutoCalling.current = true;
      return;
    }
    if (inAutocall) return;

    autoCaller();
  }, [channelGameStore, inAutocall, currentlyAutocalling]);

  const autoCaller = async () => {
    setCurrentlyAutocalling(true);
    setInAutocall(true);
    const result = await GameChannelClient.callNumber({}, handle400);
    if (result) {
      AudioService.newBall();
      channelGameStore.actCallNumber(result);
      await Utils.timeout(500);
      if (result.requiresRunOff || result.winningCards.length > 0) {
        killAutoCalling.current = false;
        setInAutocall(false);
        setCurrentlyAutocalling(false);
        return;
      }

      setInAutocall(false);
      await Utils.timeout(500);
      if (!killAutoCalling.current) {
        autoCaller();
      } else {
        killAutoCalling.current = false;
      }
    }
  };
  const game = channelGameStore.game;
  if (!game) {
    return <></>;
  }

  const isOrWasTie = channelGameStore.isTie || game.runOffNumbersCalled.length > 0;
  const showTieDialog = isOrWasTie && game.runOffNumbersCalled.length === 0;
  const gameOver = game.state === 'winner-no-tie';

  const onStartNewGame = useCallback(async () => {
    const result = await GameChannelClient.startNewGame({}, handle400);
    if (result) {
      history.push('/configure/' + result.configurationKey);
    }
  }, [history]);

  useEffect(() => {
    AudioService.setGameAudioSpeed(channelGameStore.isTie ? 'fast' : 'slow');
  }, [channelGameStore.isTie]);

  const calledNumbers = isOrWasTie ? game.runOffNumbersCalled : game.numbersCalled;
  return (
    <>
      <img src={logo} className={'game-logo'} />
      <Box className={'mv2'}>
        <Box col justify align>
          <Box justify>
            <BingoEmoteList isTie={isOrWasTie} calledNumbers={calledNumbers} emotes={game.configuration.emotes} />
          </Box>
          {isOrWasTie && (
            <Box>
              <div className={'tie-winner-grid'}>
                {channelGameStore.boardStatistics.map((viewer) => (
                  <BoxCard key={viewer.boardId} elevation={1} theme={'grey'} header={viewer.viewerName}>
                    {channelGameStore.gameBoards[viewer.boardId] ? (
                      <BingoBoard
                        size={'regular'}
                        gameStarted
                        key={viewer.boardId}
                        board={channelGameStore.gameBoards[viewer.boardId]}
                        emotes={game.configuration.emotes}
                        numbersCalled={game!.runOffNumbersCalled}
                        patterns={['tie']}
                      />
                    ) : (
                      <div className={'bingo-board-blank'}>
                        <Spinner size={30} />
                      </div>
                    )}
                  </BoxCard>
                ))}
              </div>
            </Box>
          )}
          <Box align className={'mv2'}>
            <CalledBallView calledNumbers={calledNumbers} gameId={game.gameId} emotes={game.configuration.emotes} />
            <Box col align>
              {!showTieDialog && !gameOver && (
                <BoxCardButton
                  onClick={onCallNumber}
                  theme={currentlyAutocalling ? 'warning' : 'success'}
                  style={{fontSize: '2rem'}}
                >
                  {currentlyAutocalling ? (
                    <>
                      {I.get('game.pause')}
                      <br />
                      {I.get('game.callingNumbers')}
                    </>
                  ) : (
                    <>
                      {I.get('game.start')}
                      <br />
                      {I.get('game.callingNumbers')}
                    </>
                  )}
                </BoxCardButton>
              )}
            </Box>
          </Box>
        </Box>
        {!isOrWasTie && (
          <Box flex={0} style={{alignItems: 'flex-start'}}>
            <BoxCardList theme={'grey'} elevation={3} header={I.get('game.leaderboard')} className={'mh1'}>
              {Utils.groupByArr(channelGameStore.boardStatistics, (a) => a.viewerName).map((boards) => (
                <BoardStatisticView key={boards.key} boards={boards.items} />
              ))}
            </BoxCardList>
          </Box>
        )}
      </Box>
      {showTieDialog && (
        <Box className={'modal'} justify align col>
          <BoxCard
            elevation={2}
            theme={'gold'}
            header={I.get('game.tie')}
            className={'animated backInUp'}
            style={{maxHeight: '50vh', overflowY: 'scroll'}}
          >
            {game.runOffWinningCards.map((viewer) => (
              <Box align key={viewer.viewerName} className={'winning-viewer'}>
                <img className={'img'} alt={viewer.viewerName} src={viewer.viewerAvatar} />
                <span className={'name'}>{viewer.viewerName}</span>
              </Box>
            ))}
          </BoxCard>
          <BoxCardButton theme={'success'} onClick={onCallNumber} className={'mv1'}>
            {I.get('game.startTieBreaker')}
          </BoxCardButton>
        </Box>
      )}
      {gameOver && (
        <Box className={'modal'}>
          <Box className={'animated backInUp'} justify align col>
            <BoxCard elevation={2} theme={'gold'} header={I.get('viewer.winner')}>
              {game.winningCards.map((viewer) => (
                <Box align key={viewer.viewerName} className={'winning-viewer'}>
                  <img className={'img'} alt={viewer.viewerName} src={viewer.viewerAvatar} />
                  <span className={'name'}>{viewer.viewerName}</span>
                </Box>
              ))}
              <Box col justify align className={'m2 wrap text-center'}>
                {I.get('viewer.youHaveWon')}:
                <BoxSquare theme={'gold'} style={{fontSize: '3rem'}}>
                  {PrizeToPrettyPrize(game.configuration.prize)}
                </BoxSquare>
              </Box>
            </BoxCard>
            <PromiseBoxCardButton theme={'success'} onClick={onStartNewGame} style={{fontSize: 30}} className={'mv1'}>
              {I.get('game.startANewGame')}
            </PromiseBoxCardButton>
          </Box>
        </Box>
      )}
    </>
  );
});
