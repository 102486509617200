import {GameConfigurationGamePatternName} from '../dataServices/app.generated';
import {FC, useState} from 'react';
import React from 'react';
import {Utils} from '../utils';
import {gamePatternNameToAverages, gamePatternNameToGamePatterns, GamePatterns} from '../game/gamePatterns';
import {Box} from './box';
import {bingoLetters} from '../game/bingo';
import {ButtonBoxCardSmall} from './boxCard';

export const GamePatternList: FC<{pattern: GameConfigurationGamePatternName; showAverage: boolean}> = ({
  pattern,
  showAverage,
}) => {
  const gamePatterns = gamePatternNameToGamePatterns(pattern);
  const [low, high] = gamePatternNameToAverages(pattern);

  const [currentPattern, setCurrentPattern] = useState(0);

  return (
    <Box col justify align>
      <div key={gamePatterns[currentPattern]} className={'pattern-board'}>
        {Utils.range(0, 5).map((c) => (
          <div key={`header-${c}`} className={`header`}>
            {bingoLetters[c]}
          </div>
        ))}
        {Utils.range(0, 5).map((c) => (
          <div key={`header-${c}_gap`} className={`_`} />
        ))}

        {Utils.range(1, 26).map((p) => (
          <div
            key={p}
            className={`position-${p} ${
              GamePatterns[gamePatterns[currentPattern]].some((c) => c === `${p}`) ? 'tile-enabled' : 'tile-disabled'
            }`}
          />
        ))}
      </div>
      {gamePatterns.length > 1 && (
        <Box>
          <ButtonBoxCardSmall
            theme={'grey'}
            disabled={currentPattern === 0}
            onClick={() => setCurrentPattern(currentPattern - 1)}
          >
            &lt;
          </ButtonBoxCardSmall>
          <ButtonBoxCardSmall
            theme={'grey'}
            disabled={currentPattern === gamePatterns.length - 1}
            onClick={() => setCurrentPattern(currentPattern + 1)}
          >
            &gt;
          </ButtonBoxCardSmall>
        </Box>
      )}
      {showAverage && (
        <span style={{fontSize: 18, marginTop: 5, color: '#a7b6c2'}}>
          Average number of calls: {low}-{high}
        </span>
      )}
    </Box>
  );
};
