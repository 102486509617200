//tslint:disable
import en_us from '../i18n/data/en-us';
import es_mx from '../i18n/data/es-mx';
export class Translations {
  'game.leaderboard': undefined;
  'game.pause': undefined;
  'game.start': undefined;
  'game.callingNumbers': undefined;
  'game.namesGame': {name: any};
  'game.countBoardsPurchased': {count: any};
  'game.countBoardsPurchasedSingular': {count: any};
  'game.noPlayersYet': undefined;
  'game.startGame': undefined;
  'game.changeConfiguration': undefined;
  'game.manageGame': undefined;
  'game.configureNewGame': undefined;
  'game.offline': undefined;
  'game.onlyBroadcaster': undefined;
  'game.tie': undefined;
  'game.emoteBingo': undefined;
  'game.startTieBreaker': undefined;
  'game.startANewGame': undefined;
  'config.configureYourGame': undefined;
  'config.costOfCardHelper': undefined;
  'config.costOfCardLabel': undefined;
  'config.costOfCardFree': undefined;
  'config.maxCardsPerViewerHelper': undefined;
  'config.maxCardsPerViewerLabel': undefined;
  'config.prizeHelper': undefined;
  'config.prizeLabel': undefined;
  'config.prizeDescriptionLabel': undefined;
  'config.gamePatternHelper': undefined;
  'config.gamePatternLabel': undefined;
  'config.gamePatternSee': undefined;
  'config.emotesHelper': undefined;
  'config.allowViewersToPurchase': undefined;
  'config.emotesRandomize': undefined;
  'config.noConfigNeeded': undefined;
  'config.headToStreamManager': undefined;
  'viewer.buyACard': undefined;
  'viewer.gamePrize': undefined;
  'viewer.pattern': undefined;
  'viewer.prizeDisclaimer': undefined;
  'viewer.buyAnotherCard': undefined;
  'viewer.buy': undefined;
  'viewer.cards': {count: any};
  'viewer.youLost': undefined;
  'viewer.winner': undefined;
  'viewer.sorry': undefined;
  'viewer.youHaveWon': undefined;
  'viewer.noActiveGames': undefined;
  'viewer.askStreamerToStart': undefined;
  'viewer.checkAgain': undefined;
  'viewer.gettingGameDetails': undefined;
  'viewer.watchingTieBreaker': undefined;
  'viewer.gameIsOver': undefined;
  'viewer.pop': undefined;
  'generic.errorHasOccurred': undefined;
  'generic.navigateBackToDashboard': undefined;
  'generic.accountNotAuthorized': undefined;
}
export class TranslationManager {
  static getTranslation(locale: string): any {
    switch (locale) {
      case 'en-us':
        return en_us;
      case 'es-mx':
        return es_mx;
    }
  }
}
