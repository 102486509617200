import {BoardPosition} from '../dataServices/app.generated';
import {unreachable} from '../utils';

export const GamePatterns: {[key in GameConfigurationGamePattern]: BoardPosition[]} = {
  'line-1': ['1', '2', '3', '4', '5'],
  'line-2': ['6', '7', '8', '9', '10'],
  'line-3': ['11', '12', '14', '15'],
  'line-4': ['16', '17', '18', '19', '20'],
  'line-5': ['21', '22', '23', '24', '25'],
  'line-b': ['1', '6', '11', '16', '21'],
  'line-i': ['2', '7', '12', '17', '22'],
  'line-n': ['3', '8', '18', '23'],
  'line-g': ['4', '9', '14', '19', '24'],
  'line-o': ['5', '10', '15', '20', '25'],
  'line-left-diag': ['1', '7', '19', '25'],
  'line-right-diag': ['5', '9', '17', '21'],
  'big-square': ['1', '2', '3', '4', '5', '6', '10', '11', '15', '16', '20', '21', '22', '23', '24', '25'],
  'small-square': ['7', '8', '9', '12', '14', '17', '18', '19'],
  'turtle-up': [
    '2',
    '3',
    '4',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '22',
    '24',
  ],
  'turtle-down': ['2', '4', '6', '7', '8', '9', '10', '11', '12', '14', '15', '16', '17', '18', '19', '21', '22', '23'],
  tie: ['1', '2', '3'],
  'full-board': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
  ],
};

export function gamePatternNameToGamePatterns(
  gamePatternName: GameConfigurationGamePatternName
): GameConfigurationGamePattern[] {
  switch (gamePatternName) {
    case 'bingo':
      return GameConfigurationGamePatternLines;
    case 'big-square':
      return [gamePatternName];
    case 'small-square':
      return [gamePatternName];
    case 'full-board':
      return [gamePatternName];
    case 'turtle':
      return ['turtle-down', 'turtle-up'];
    default:
      throw unreachable(gamePatternName);
  }
}
export function gamePatternNameToAverages(gamePatternName: GameConfigurationGamePatternName): [number, number] {
  switch (gamePatternName) {
    case 'bingo':
      return [6, 10];
    case 'big-square':
      return [17, 25];
    case 'small-square':
      return [10, 15];
    case 'full-board':
      return [50, 60];
    case 'turtle':
      return [40, 50];
    default:
      throw unreachable(gamePatternName);
  }
}

export type GameConfigurationGamePatternName = 'bingo' | 'big-square' | 'small-square' | 'full-board' | 'turtle';

export const GameConfigurationGamePatternLines: GameConfigurationGamePattern[] = [
  'line-b',
  'line-i',
  'line-n',
  'line-g',
  'line-o',
  'line-1',
  'line-2',
  'line-3',
  'line-4',
  'line-5',
  'line-left-diag',
  'line-right-diag',
];
export type GameConfigurationGamePattern =
  | 'line-b'
  | 'line-i'
  | 'line-n'
  | 'line-g'
  | 'line-o'
  | 'line-1'
  | 'line-2'
  | 'line-3'
  | 'line-4'
  | 'line-5'
  | 'line-left-diag'
  | 'line-right-diag'
  | 'big-square'
  | 'small-square'
  | 'full-board'
  | 'turtle-up'
  | 'turtle-down'
  | 'tie';
