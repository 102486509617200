import React from 'react';
import {channelAuthStore, ChannelAuthStoreProps} from './channelAuthStore';
import {channelGameStore, ChannelGameStoreProps} from './channelGameStore';
import {uiStore, UIStoreProps} from './uiStore';

export type WebStoreProps = ChannelAuthStoreProps & ChannelGameStoreProps & UIStoreProps;

export const webStores: WebStoreProps = {
  channelGameStore,
  channelAuthStore,
  uiStore,
};

export const WebStoresContext = React.createContext<WebStoreProps>({
  channelGameStore,
  channelAuthStore,
  uiStore,
});

export function useWebStores(): WebStoreProps {
  return React.useContext(WebStoresContext);
}
