import './home.scss';
import {FC, ReactNode, useCallback, useRef} from 'react';
import React from 'react';
import {observer} from 'mobx-react';
import {useEffectAsync} from '@common/hooks/useEffectAsync';
import {Box} from '@common/components/box';
import {BoxCard, BoxCardButton, BoxSquare} from '@common/components/boxCard';
import Logo from '@common/assets/logo-wide.png';
import video1 from '../../videos/1.webm';
import video2 from '../../videos/2.webm';
import video3 from '../../videos/3.webm';
import video4 from '../../videos/4.webm';
import video5 from '../../videos/5.webm';
import video6 from '../../videos/6.webm';
import video7 from '../../videos/7.webm';
import {AnalyticsClient} from '@common/dataServices/app.generated';
import {Footer} from './components/footer';
import useInView from 'react-cool-inview';

export const Home: FC = observer(() => {
  useEffectAsync(async () => {}, []);

  const onInstallNow = useCallback(async (area: 'top' | 'bottom') => {
    window.open('https://dashboard.twitch.tv/extensions/l9gec9uk58fiwl1lrszz4yr1a73l0e', 'blank');
    await AnalyticsClient.event(
      {eventName: 'install-website', metaData: area, time: new Date().toISOString()},
      {500: () => {}}
    );
  }, []);

  const myRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () =>
    window.scrollTo({
      top: myRef.current?.offsetTop,
      behavior: 'smooth',
    });

  return (
    <>
      <Box col justify align className={'section section-top'}>
        <img alt={'Emote Bingo!'} src={Logo} className={'home-logo'} />
      </Box>
      <Box justify align className={`section-header`}>
        <Box justify align className={`section-text wrap`} style={{fontSize: '3rem'}}>
          Play a game of bingo on your{'\n'}Twitch stream with your viewers!
        </Box>
        <BoxCard elevation={2} theme={'grey'}>
          <video controls src={video1} autoPlay className={'home-video-header'} loop muted />
        </BoxCard>
      </Box>
      <Box col justify align className={'section section-top'}>
        <Box col justify align className={'section'}>
          <BoxCardButton
            onClick={() => onInstallNow('top')}
            style={{letterSpacing: 3}}
            theme={'success'}
            className={'install-now'}
          >
            Install The Twitch Extension Now!
          </BoxCardButton>
          <BoxSquare theme={'gold'} style={{padding: 0, marginTop: '2rem'}} onClick={scrollToTop}>
            <Box>
              <span className={'learn-more'}>Learn More!</span>
              <div className="downArrow bounce">
                <svg enableBackground={'new 0 0 32 32'} height="50px" viewBox="0 0 32 32" width="50px">
                  <path
                    d="M24.285,11.284L16,19.571l-8.285-8.288c-0.395-0.395-1.034-0.395-1.429,0  c-0.394,0.395-0.394,1.035,0,1.43l8.999,9.002l0,0l0,0c0.394,0.395,1.034,0.395,1.428,0l8.999-9.002  c0.394-0.395,0.394-1.036,0-1.431C25.319,10.889,24.679,10.889,24.285,11.284z"
                    fill="white"
                    id="Expand_More"
                  />
                </svg>
              </div>
            </Box>
          </BoxSquare>
        </Box>
      </Box>
      <div ref={myRef} />

      <Section>
        {(inView) => (
          <>
            <Box justify align className={`section-text animated ${inView ? '-slideInLeft' : ''}`}>
              <span>
                <span style={{fontWeight: 800}}>1: </span>Configure your game mode and prize
              </span>
            </Box>
            <BoxCard
              elevation={2}
              theme={'grey'}
              header={'Game Mode & Prize'}
              className={`animated ${inView ? '-slideInRight' : ''}`}
            >
              <video controls src={video2} autoPlay className={'home-video'} loop muted />
            </BoxCard>
          </>
        )}
      </Section>
      <Section className={'section-r'}>
        {(inView) => (
          <>
            <BoxCard
              elevation={2}
              header={'Emotes'}
              theme={'grey'}
              className={`animated ${inView ? '-slideInLeft' : ''}`}
            >
              <video controls src={video3} autoPlay className={'home-video'} loop muted />
            </BoxCard>
            <Box justify align className={`section-text animated ${inView ? '-slideInRight' : ''}`}>
              <span>
                <span style={{fontWeight: 800}}>2: </span>Customize your game using your Twitch Emotes or fun Animal
                Emotes
              </span>
            </Box>
          </>
        )}
      </Section>
      <Section>
        {(inView) => (
          <>
            <Box justify align className={`section-text animated ${inView ? '-slideInLeft' : ''}`}>
              <span>
                <span style={{fontWeight: 800}}>3: </span>Open the game up to viewers live on stream!
              </span>
            </Box>
            <BoxCard
              header={'Go Live'}
              elevation={2}
              theme={'grey'}
              className={`animated ${inView ? '-slideInRight' : ''}`}
            >
              <video controls src={video4} autoPlay className={'home-video'} loop muted />
            </BoxCard>
          </>
        )}
      </Section>
      <Section className={'section-r'}>
        {(inView) => (
          <>
            <BoxCard
              header={'Grab Cards'}
              elevation={2}
              theme={'grey'}
              className={`animated ${inView ? '-slideInLeft' : ''}`}
            >
              <video controls src={video5} autoPlay className={'home-video'} loop muted />
            </BoxCard>
            <Box justify align className={`section-text animated ${inView ? '-slideInRight' : ''} smaller-text`}>
              Now your viewers grab cards using bits overlayed directly on your channel on desktop AND mobile!
            </Box>
          </>
        )}
      </Section>
      <Section>
        {(inView) => (
          <>
            <Box justify align className={`section-text animated ${inView ? '-slideInLeft' : ''}  smaller-text`}>
              Play the game on your stream while viewers watch their cards update in real time
            </Box>
            <BoxCard
              header={'Play Bingo'}
              elevation={2}
              theme={'grey'}
              className={`animated ${inView ? '-slideInRight' : ''}`}
            >
              <video controls src={video6} autoPlay className={'home-video'} loop muted />
            </BoxCard>
          </>
        )}
      </Section>
      <Section className={'section-r'}>
        {(inView) => (
          <>
            <BoxCard
              header={'Winner!'}
              elevation={2}
              theme={'grey'}
              className={`animated ${inView ? '-slideInLeft' : ''}`}
            >
              <video controls src={video7} autoPlay className={'home-video'} loop muted />
            </BoxCard>
            <Box justify align className={`section-text animated ${inView ? '-slideInRight' : ''}  smaller-text`}>
              When the player wins, celebrate and let them claim their prize!
            </Box>
          </>
        )}
      </Section>
      <Box justify align className={'section'} style={{marginTop: '3rem', marginBottom: '3rem'}}>
        <BoxCardButton
          style={{letterSpacing: 3}}
          onClick={() => onInstallNow('bottom')}
          theme={'success'}
          className={'install-now'}
        >
          Install The Twitch Extension Now!
        </BoxCardButton>
      </Box>
      <Footer />
    </>
  );
});

export const Section: FC<{children: (inView: boolean) => ReactNode; className?: string}> = ({children, className}) => {
  const {ref, inView} = useInView({
    unobserveOnEnter: true,
  });

  return (
    <Box justify className={`section ${className}`} ref={ref as any}>
      {children(inView)}
    </Box>
  );
};
