import React, {FC, useRef} from 'react';
import {BingoNumber, determinePrefix, positionToColumn} from '../game/gameInfo';
import {CalledNumber, GameConfigurationEmotes} from '../dataServices/app.generated';
import {BoxCard} from './boxCard';
import {Box} from './box';
import {useComponentSize} from '../hooks/useComponentSize';

export const CalledBallView: FC<{
  calledNumbers: number[];
  emotes: GameConfigurationEmotes;
  gameId: string;
}> = ({emotes, gameId, calledNumbers}) => {
  const lastBall = calledNumbers[calledNumbers.length - 2];
  const nextBall = calledNumbers[calledNumbers.length - 1];

  const lastEmote = lastBall ? emotes[lastBall.toString() as BingoNumber] : undefined;
  const nextEmote = nextBall ? emotes[nextBall.toString() as BingoNumber] : undefined;
  const ref = useRef<HTMLDivElement>(null);
  const {width} = useComponentSize(ref);
  const prefix = determinePrefix(gameId, emotes);
  return (
    <>
      <Box noFlex col className={'game'} style={{alignItems: 'stretch'}}>
        <div ref={ref} className={'ball-holder box-gold'} style={{zIndex: -1}}>
          {nextBall && nextEmote && (
            <div
              key={nextBall}
              className={`ball-in-holder ball ball-${positionToColumn(
                nextBall.toString() as BingoNumber
              )} in-from-left `}
            >
              <img
                alt={`${positionToColumn(nextBall.toString() as BingoNumber)}-${nextEmote.code}`}
                src={nextEmote.url}
              />
            </div>
          )}
          {lastBall && lastEmote && (
            <div
              key={lastBall}
              className={`ball-in-holder ball ball-${positionToColumn(
                lastBall.toString() as BingoNumber
              )} out-to-right`}
            >
              <img
                alt={`${positionToColumn(lastBall.toString() as BingoNumber)}-${lastEmote.code}`}
                src={lastEmote.url}
              />
            </div>
          )}
        </div>
        {nextEmote && (
          <BoxCard theme={'gold'} elevation={2} className={'ball-holder-details'} style={{padding: 4}}>
            <Box justify align col style={{height: '100%'}}>
              <div style={{fontSize: '3rem', textAlign: 'center', wordBreak: 'break-all', maxWidth: width + 'px'}}>
                <b>{positionToColumn(nextBall.toString() as BingoNumber).toUpperCase()}</b>{' '}
                <span style={{fontWeight: 100}}>{nextEmote.code.replace(prefix, '')}</span>
              </div>
            </Box>
          </BoxCard>
        )}
      </Box>
    </>
  );
};
