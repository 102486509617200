import {action, computed, observable} from 'mobx';
import jwtDecode from 'jwt-decode';
import {TwitchJwtSchema, TwitchUserRole} from '../../types/twitch';

export class AuthStore {
  @observable opaqueId?: string;
  @observable role?: TwitchUserRole;
  @observable token?: string;
  @observable userId?: string;
  @observable fullDecoded?: TwitchJwtSchema;

  // similar to mod status, this isn't always verifiable, so have your backend verify before proceeding.
  @computed get hasSharedId() {
    return !!this.userId;
  }

  // checks to ensure there is a valid token in the state
  @computed get isAuthenticated() {
    return !!(this.token && this.opaqueId);
  }
  @computed get isBroadcaster() {
    return this.role === 'broadcaster';
  }

  @computed get isLoggedIn() {
    return this.opaqueId ? this.opaqueId[0] === 'U' : false;
  }

  // This does guarantee the user is a moderator- this is fairly simple to bypass - so pass the JWT and verify
  // server-side that this is true. This, however, allows you to render client-side UI for users without holding on a backend to verify the JWT.
  // Additionally, this will only show if the user shared their ID, otherwise it will return false.
  @computed get isModerator() {
    return this.role === 'moderator' || this.role === 'broadcaster';
  }

  @action logout() {
    this.token = undefined;
  }

  @action setToken(token: string, opaqueId: string) {
    try {
      const decoded = jwtDecode(token) as TwitchJwtSchema;
      this.token = token;
      this.fullDecoded = decoded;
      this.opaqueId = opaqueId;
      this.userId = decoded.user_id;
      this.role = decoded.role;
    } catch (e) {}
  }
}

export const authStore = new AuthStore();
export type AuthStoreProps = {authStore: AuthStore};
