import {
  BoardStatistic,
  CallNumberResponse, GameChannelClient,
  GameRulesResponse,
  HttpChannelModel,
  HttpGameModel,
  HttpViewerGameBoardModel,
} from '../../dataServices/app.generated';
import {action, computed, observable} from 'mobx';
import {GameConfigurationEmote} from 'emotebingo-server/dbModels/dbGameModel';

export class ChannelGameStore {
  @observable boardsPurchased: number = 0;
  @observable boardStatistics: CallNumberResponse['boardStatistics'] = [];
  @observable channel?: HttpChannelModel;
  @observable game?: HttpGameModel;
  @observable gameBoards: {[key: string]: HttpViewerGameBoardModel} = {};
  @observable gameRules?: GameRulesResponse;
  @observable globalEmotes: GameConfigurationEmote[] = [];

  @computed get isTie() {
    return this.game?.state === 'run-off-tie';
  }

  @action actCallNumber(result: CallNumberResponse) {
    const oldGameState = this.game!.state;
    this.game = {
      ...this.game!,
      state: result.gameState,
      winningCards: result.winningCards,
      runOffWinningCards: result.runOffWinningCards,
    };

    if (oldGameState === 'run-off-tie') {
      this.game = {
        ...this.game!,
        runOffNumbersCalled: [...this.game!.runOffNumbersCalled, result.numberCalled.number],
      };
    } else {
      this.game = {
        ...this.game!,
        numbersCalled: [...this.game!.numbersCalled, result.numberCalled.number],
      };
    }

    this.setBoardStatistics(result.boardStatistics);
  }

  @action setBoardsPurchasedGame(boardsPurchased: number) {
    this.boardsPurchased = boardsPurchased;
  }

  @action setBoardStatistics(boardStatistics: BoardStatistic[]) {
    this.boardStatistics = boardStatistics;
    this.getBoards();
  }

  @action setChannel(channel: HttpChannelModel) {
    this.channel = channel;
  }

  @action setGame(game: HttpGameModel) {
    this.game = game;
  }

  @action setGameRules(gameRules: GameRulesResponse) {
    this.gameRules = gameRules;
  }

  @action setGlobalEmotes(globalEmotes: GameConfigurationEmote[]) {
    this.globalEmotes = globalEmotes;
  }

  private async getBoards() {
    const newBoards: string[] = [];
    for (const boardStatistic of this.boardStatistics) {
      if (!(boardStatistic.boardId in this.gameBoards)) {
        newBoards.push(boardStatistic.boardId);
      }
    }
    if (newBoards.length > 0) {
      const result = await GameChannelClient.getBoards({boardIds: newBoards}, {});
      if (result) {
        this.setGameBoards(result.boards);
      }
    }
  }

  @action private setGameBoards(boards: HttpViewerGameBoardModel[]) {
    for (const board of boards) {
      this.gameBoards[board.viewerGameBoardModelId] = board;
    }
  }
}

export const channelGameStore = new ChannelGameStore();
export type ChannelGameStoreProps = {channelGameStore: ChannelGameStore};
