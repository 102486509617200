import '@common/game.scss';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import React from 'react';
import {useWebStores} from '@common/store/webStores';
import {observer} from 'mobx-react';
import {
  ChannelClient,
  ChannelSocketClient,
  ChannelSocketEvents,
  GameChannelClient,
} from '@common/dataServices/app.generated';
import {useComponentWill} from '@common/hooks/useComponentWill';
import {ConfigurationView} from './configurationView';
import {LiveGame} from './liveGame';
import {Box} from '@common/components/box';
import {BallPit} from '@common/components/ballPit';
import {BoxCard} from '@common/components/boxCard';
import {PromiseBoxCardButton} from '@common/components/promiseBoxCardButton';
import {Loading} from '@common/components/loading';
import {I} from '@common/i18n/translator';
import {handle400} from '@common/dataServices/baseClient';
import logo from '@common/assets/logo-wide.png';

const socket = new ChannelSocketClient();

export const Game: FC = observer(() => {
  const {channelAuthStore, channelGameStore} = useWebStores();
  const [loading, setLoading] = useState(true);
  const [preLoading, setPreLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const ballPitRef = useRef<BallPit | null>(null);

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return 'Are you sure you want to leave this page?';
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setPreLoading(false);
    }, 100);
  }, []);
  useComponentWill(
    async () => {
      function handleError() {
        setHasError(true);
      }
      const result = await GameChannelClient.reauthorizeGame(
        {},
        {
          400: handleError,
          500: handleError,
          401: handleError,
        }
      );
      if (result) {
        channelAuthStore.setJWT(result.jwt);
        channelGameStore.setChannel(result.channel);
        channelGameStore.setGame(result.game);
        channelGameStore.setBoardsPurchasedGame(result.boardsPurchased);
        channelGameStore.setBoardStatistics(result.boardStatistics);
        socket.connect({onConnect, onDisconnect, onCardPurchased, onCardPop});
        if (result.game.state === 'configuring') {
          GameChannelClient.getGlobalEmotes({}, {200: ({emotes}) => channelGameStore.setGlobalEmotes(emotes)});
        }
        const gameRulesResult = await ChannelClient.getGameRules({}, {});
        if (gameRulesResult) {
          channelGameStore.setGameRules(gameRulesResult);
        }
      }
      setLoading(false);
    },
    () => {
      socket.disconnect();
    }
  );

  const onConnect = useCallback(async () => {}, []);
  const onDisconnect = useCallback(async () => {}, []);
  const onCardPurchased: ChannelSocketEvents['onCardPurchased'] = useCallback(
    (e) => {
      channelGameStore.setBoardsPurchasedGame(e.cardsPurchased);
    },
    [channelGameStore]
  );
  const onCardPop: ChannelSocketEvents['onCardPop'] = useCallback(
    (e) => {
      if (ballPitRef.current) {
        ballPitRef.current.pop();
      }
    },
    [ballPitRef]
  );

  const onStartGame = useCallback(async () => {
    const result = await GameChannelClient.startGame({}, handle400);
    if (result) {
      channelGameStore.setGame(result.game);
      channelGameStore.setBoardStatistics(result.boardStatistics);
    }
  }, [channelGameStore]);

  const onChangeConfiguration = useCallback(async () => {
    const result = await GameChannelClient.setBackToConfiguration({}, handle400);
    if (result) {
      channelGameStore.setGame(result.game);
    }
  }, [channelGameStore]);

  if (hasError) {
    return (
      <Box justify align style={{width: '100vw', height: '100vh'}}>
        <BoxCard theme={'danger'} elevation={2} header={`Whoops!`}>
          <Box justify align col className={'text-center'}>
            <h3>
              {I.get('generic.errorHasOccurred')}
              <br />
              <br />
              {I.get('generic.navigateBackToDashboard')}
            </h3>
          </Box>
        </BoxCard>
      </Box>
    );
  }
  if (loading || preLoading) {
    return preLoading ? (
      <>
        <img src={logo} className={'game-logo'} />
        <span style={{fontWeight: 100, position: 'absolute', opacity: 0}}>a</span>
        <span style={{fontWeight: 300, position: 'absolute', opacity: 0}}>a</span>
        <span style={{fontWeight: 400, position: 'absolute', opacity: 0}}>a</span>
        <span style={{fontWeight: 900, position: 'absolute', opacity: 0}}>a</span>
      </>
    ) : (
      <>
        <img src={logo} className={'game-logo'} />
        <Box justify align style={{width: '100vw', height: '100vh'}}>
          <Loading />
        </Box>
      </>
    );
  }

  if (!channelGameStore.channel || !channelGameStore.game) {
    return <></>;
  }
  switch (channelGameStore.game.state) {
    case 'created':
      return <></>;
    case 'configuring':
      return <ConfigurationView />;
    case 'boards-buyable':
      return (
        <>
          <BallPit
            key={channelGameStore.game.gameId}
            ref={ballPitRef}
            emotes={channelGameStore.game.configuration.emotes}
            count={channelGameStore.boardsPurchased}
          />
          <img src={logo} className={'game-logo'} />
          <Box justify align col className={'full-screen'} style={{position: 'absolute'}}>
            <BoxCard
              theme={'grey'}
              elevation={2}
              header={I.get('game.namesGame', {name: channelGameStore.channel.channelDetails.display_name})}
            >
              <Box justify align col>
                {channelGameStore.boardsPurchased > 0 ? (
                  channelGameStore.boardsPurchased === 1 ? (
                    <h3>{I.get('game.countBoardsPurchasedSingular', {count: channelGameStore.boardsPurchased})}</h3>
                  ) : (
                    <h3>{I.get('game.countBoardsPurchased', {count: channelGameStore.boardsPurchased})}</h3>
                  )
                ) : (
                  <h3>{I.get('game.noPlayersYet')}</h3>
                )}
              </Box>
            </BoxCard>
            <Box justify align flex={0}>
              <PromiseBoxCardButton
                className={'m1'}
                theme={'success'}
                onClick={onStartGame}
                disabled={channelGameStore.boardsPurchased === 0}
              >
                {I.get('game.startGame')}
              </PromiseBoxCardButton>
              {channelGameStore.boardsPurchased === 0 && (
                <PromiseBoxCardButton className={'m1'} theme={'warning'} onClick={onChangeConfiguration}>
                  {I.get('game.changeConfiguration')}
                </PromiseBoxCardButton>
              )}
            </Box>
          </Box>
        </>
      );
    case 'started':
    case 'run-off-tie':
    case 'winner-no-tie':
      return <LiveGame />;
  }
  throw new Error('Cannot find state');
});
