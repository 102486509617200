import React, {FC} from 'react';
import {
  CalledNumber,
  GameConfigurationEmotes,
  GameConfigurationGamePattern,
  HttpViewerGameBoardModel,
} from '../dataServices/app.generated';
import {GamePatterns} from '../game/gamePatterns';
import {Utils} from '../utils';
import {BingoNumber} from '../game/gameInfo';
import classNames from 'classnames';
import {bingoLetters} from '../game/bingo';
import {Tooltip} from '@blueprintjs/core/lib/esm/components/tooltip/tooltip';

export const BingoBoard: FC<{
  board: HttpViewerGameBoardModel;
  emotes: GameConfigurationEmotes;
  gameStarted: boolean;
  numbersCalled: number[];
  patterns: GameConfigurationGamePattern[];
  size: 'small' | 'mini' | 'regular';
}> = ({patterns, emotes, board, numbersCalled, gameStarted, size}) => {
  if (board.tieBoard) {
    return (
      <div className={`bingo-board tie-bingo-board`}>
        {Utils.range(1, 4).map((p) => {
          const boardNumber = board.numbers.find((a) => a.position === `${p}`)!;

          const bingoNumber = boardNumber.number.toString() as BingoNumber;
          const emote = emotes[bingoNumber];

          const called = numbersCalled.some((n) => n === boardNumber.number);
          const url = emote.url;
          const alt = emote.code;

          return (
            <div
              key={p}
              className={classNames({'box-gold': called}, `tile box-grey`, `position`, `position-${p}`, {
                ['tile-enabled']: true,
              })}
            >
              <Tooltip content={<h2> {alt}</h2>} portalClassName={'bp3-light'}>
                <img alt={alt} key={boardNumber.number} src={url} />
              </Tooltip>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classNames(`bingo-board`, {[`${size}-bingo-board`]: true})}>
      {Utils.range(0, 5).map((c) => (
        <div key={`header-${c}`} className={classNames(`tile box-grey`, `header`)}>
          {bingoLetters[c]}
        </div>
      ))}
      {Utils.range(0, 5).map((c) => (
        <div key={`gap-${c}`} className={'_'} />
      ))}

      {Utils.range(1, 26).map((p) => {
        const boardNumber = [...board.numbers, {position: '13', column: 'n', number: 0}].find(
          (a) => a.position === `${p}`
        )!;

        const bingoNumber = boardNumber.number.toString() as BingoNumber;
        const emote = emotes[bingoNumber];

        const tileEnabled = p === 13 || patterns.some((pat) => GamePatterns[pat].some((c) => c === `${p}`));
        const called = gameStarted && (p === 13 || numbersCalled.some((n) => n === boardNumber.number));
        const url = p === 13 ? emotes.freeSpace.url : emote.url;
        const alt = p === 13 ? 'Free Space' : emote.code;

        return (
          <div
            key={p}
            className={classNames({'box-gold': called}, `tile box-grey`, `position`, `position-${p}`, {
              ['tile-enabled']: tileEnabled,
              ['tile-disabled']: !tileEnabled,
            })}
          >
            <Tooltip
              content={
                <h2>
                  <i>{boardNumber.column.toUpperCase()}</i> {alt}
                </h2>
              }
              disabled={size === 'mini'}
              portalClassName={'bp3-light'}
            >
              <img alt={alt} key={boardNumber.number} src={url} />
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};
