import React from 'react';
import {TranslationManager, Translations} from './translations';
import {flipString} from './i18nUtils';

const flip = false;

export const TranslationContext = React.createContext<{
  locale: string;
  setLocale: (locale: string) => void;
}>({
  locale: 'en-us',
  setLocale: (locale) => {
    // localStorage.setItem('locale', locale);
  },
});

export class I {
  private static currentFile: any;

  static get<K extends keyof Translations, P extends Translations[K]>(
    ...args: P extends undefined ? [K] : [K, P]
  ): string;
  static get<K extends keyof Translations, P extends Translations[K]>(key: K, replaceKeys: any): string {
    const keys = key.split('.');
    let translated = this.currentFile || TranslationManager.getTranslation('en-us');
    for (const k of keys) {
      if (translated[k] === undefined) {
        translated = '';
      } else {
        translated = translated[k];
      }
      if (typeof translated === 'string' && replaceKeys) {
        for (const replaceKey in replaceKeys) {
          if (replaceKeys.hasOwnProperty(replaceKey)) {
            translated = translated.replace(`{${replaceKey}}`, (replaceKeys as any)[replaceKey]);
          }
        }
      }
    }
    if (flip) {
      return flipString(translated);
    }
    return translated;
  }

  static start() {}
  static updateLocale(locale: string) {
    this.currentFile = TranslationManager.getTranslation(locale ? locale.toLowerCase() : 'en-us');
  }
}
