import './boardStatisticsView.scss';
import React, {FC} from 'react';
import {BoardStatistic, CalledNumber, GameConfigurationGamePattern} from '@common/dataServices/app.generated';
import {observer} from 'mobx-react';
import {BingoBoard} from '@common/components/bingoBoard';
import {Spinner} from '@blueprintjs/core';
import {Box} from '@common/components/box';
import {useWebStores} from '@common/store/webStores';

export const BoardStatisticView: FC<{boards: BoardStatistic[]}> = observer(({boards}) => {
  const {channelGameStore} = useWebStores();
  const viewer = boards.sort((a, b) => a.numberLeft - b.numberLeft)[0];

  const currentGame = channelGameStore.game!;

  let numbersCalled: number[];
  let gamePatterns: GameConfigurationGamePattern[];

  if (currentGame.state === 'run-off-tie') {
    numbersCalled = currentGame.runOffNumbersCalled;
    gamePatterns = ['tie'];
  } else {
    numbersCalled = currentGame.numbersCalled;
    gamePatterns = currentGame.configuration.gamePatterns;
  }
  const lowestBoardId = [...boards].sort((a, b) => a.numberLeft - b.numberLeft)[0].boardId;
  return (
    <div key={viewer.boardId} className={'board-stat'}>
      <Box justify align col>
        <Box justify align flex={1}>
          <img className={'img'} alt={viewer.viewerName} src={viewer.viewerAvatar} />
          <span className={'name'}>{viewer.viewerName}</span>
          <span className={'count'}>{viewer.numberLeft} Left</span>
        </Box>
        <div className={'mt1'}>
          {channelGameStore.gameBoards[lowestBoardId] ? (
            <BingoBoard
              size={'small'}
              gameStarted
              key={lowestBoardId}
              board={channelGameStore.gameBoards[lowestBoardId]}
              emotes={currentGame.configuration.emotes}
              numbersCalled={numbersCalled}
              patterns={gamePatterns}
            />
          ) : (
            <div className={'bingo-board-blank'}>
              <Spinner size={30} />
            </div>
          )}
        </div>
      </Box>
    </div>
  );
});
