import React, {forwardRef, MutableRefObject, useEffect, useRef, useState} from 'react';
import planck, {Body, Edge, Vec2} from 'planck-js';
import {useComponentWill} from '../hooks/useComponentWill';
import {Utils} from '../utils';
import {GameConfigurationEmote, GameConfigurationEmotes} from '../dataServices/app.generated';

type PhysicsBall = {emote: GameConfigurationEmote; index: number; planckBody: Body};
export type BallPit = {
  pop: () => void;
};

const colors = [
  {
    color1: 'hsl(52, 85%, 52%)',
    color2: 'hsl(52, 85%, 40%)',
    color3: 'hsl(52, 85%, 35%)',
  },
  {
    color1: 'hsl(123, 85%, 52%)',
    color2: 'hsl(123, 85%, 40%)',
    color3: 'hsl(123, 85%, 35%)',
  },
  {
    color1: 'hsl(183, 85%, 52%)',
    color2: 'hsl(183, 85%, 40%)',
    color3: 'hsl(183, 85%, 35%)',
  },
  {
    color1: 'hsl(284, 85%, 52%)',
    color2: 'hsl(284, 85%, 40%)',
    color3: 'hsl(284, 85%, 35%)',
  },
  {
    color1: 'hsl(0, 85%, 52%)',
    color2: 'hsl(0, 85%, 40%)',
    color3: 'hsl(0, 85%, 35%)',
  },
];

export const BallPit = forwardRef<BallPit, {count: number; emotes: GameConfigurationEmotes}>(
  ({emotes, count}, ballPitRef) => {
    const safeKeys = Utils.safeKeys(emotes).filter((a) => emotes[a]);

    const oldCount = useRef(count);
    const [balls, setBalls] = useState<PhysicsBall[]>([]);
    const ballsRef = useRef(balls);
    ballsRef.current = balls;

    const worldRef = useRef(planck.World({gravity: planck.Vec2(0, 50)}));
    const boardSize = useRef({width: document.body.offsetWidth, height: document.body.offsetHeight});
    const canvas = useRef<HTMLCanvasElement>(null);
    const ballSize = Math.min(boardSize.current.width, boardSize.current.height) * 0.07;
    const createBall = (index: number, emote: keyof GameConfigurationEmotes) => {
      const world = worldRef.current;
      const body = world.createBody();
      body.setDynamic();
      body.setUserData(index);
      body.setPosition(
        Vec2(
          Math.random() * boardSize.current.width * 0.8 + 100,
          20 + Math.random() * boardSize.current.height * 0.1 + 100
        )
      );
      const fixture = body.createFixture({
        shape: planck.Circle(ballSize),
      });
      fixture.setDensity(0.5);
      fixture.setFriction(0.4);
      fixture.setRestitution(0.6);
      body.setMassData({mass: 1000 + Math.random() * 10, center: Vec2(), I: 1});
      return {
        index,
        emote: emotes[emote],
        planckBody: body,
      };
    };

    useEffect(() => {
      if (oldCount.current !== count) {
        const newBalls: PhysicsBall[] = [];
        for (let i = oldCount.current; i < count; i++) {
          newBalls.push(createBall(i, Utils.randomElement(safeKeys)));
        }
        oldCount.current = count;
        const world = worldRef.current;
        const updatedBalls = [...balls, ...newBalls];
        for (let i = 0; i < updatedBalls.length - 50; i++) {
          const newBall = updatedBalls[i];
          world.destroyBody(newBall.planckBody);
        }
        setBalls(updatedBalls.slice(-50));
      }
    }, [count, balls]);

    const isDone = useRef(false);
    useComponentWill(
      () => {
        const world = worldRef.current;
        const ground = world.createBody(Vec2(0.0, 20.0));
        const wallFD = {
          density: 0.0,
          restitution: 0.4,
        };
        // Left vertical
        ground.createFixture(Edge(Vec2(0, 0), Vec2(0, boardSize.current.height)), wallFD);

        // Right vertical
        ground.createFixture(
          Edge(Vec2(boardSize.current.width, 0), Vec2(boardSize.current.width, boardSize.current.height)),
          wallFD
        );

        // Top horizontal
        ground.createFixture(Edge(Vec2(0, -30), Vec2(boardSize.current.width, -30)), wallFD);

        // Bottom horizontal
        ground.createFixture(
          Edge(Vec2(0, boardSize.current.height), Vec2(boardSize.current.width, boardSize.current.height)),
          wallFD
        );

        const newBalls: PhysicsBall[] = [];
        for (let i = Math.max(0, count - 50); i < count; i++) {
          newBalls.push(createBall(i, Utils.randomElement(safeKeys)));
        }
        oldCount.current = count;
        setBalls(newBalls);
        ballsRef.current = newBalls;

        (function loop() {
          world.step(1 / 60);
          world.step(1 / 60);
          world.step(1 / 60);
          world.step(1 / 60);

          if (canvas.current) {
            const context = canvas.current.getContext('2d')!;
            context.clearRect(0, 0, canvas.current.width, canvas.current.height);
            for (let body = world.getBodyList(); body; body = body.getNext()) {
              if (body.getUserData() === null) continue;
              const index = body.getUserData() as number;
              const {x, y} = body.getPosition();
              const angle = body.getAngle();

              context.save();

              context.translate(x, y);
              context.rotate(angle);

              context.beginPath();
              context.arc(0, 0, ballSize, 0, Math.PI * 2);
              context.fillStyle = colors[index % 5].color1;
              context.fill();

              context.beginPath();
              context.arc(0 + ballSize * 0.075, 0 - ballSize * 0.075, ballSize * 0.9, 0, Math.PI * 2);
              context.fillStyle = colors[index % 5].color2;
              context.fill();

              context.beginPath();
              context.arc(0 + ballSize * 0.125, 0 - ballSize * 0.125, ballSize * 0.7, 0, Math.PI * 2);
              context.fillStyle = colors[index % 5].color3;
              context.fill();

              context.beginPath();
              context.arc(0, 0, ballSize * 0.6, 0, Math.PI * 2);
              context.fillStyle = 'white';
              context.fill();

              const c = ballsRef.current[index % ballsRef.current.length];

              if (c) {
                const size = ballSize * 0.8;
                context.drawImage(getImage(c.emote.url), -size / 2, -size / 2, size, size);
              }
              context.restore();
            }
          }
          if (!isDone.current) {
            window.requestAnimationFrame(loop);
          }
        })();
      },
      () => {
        isDone.current = true;
      }
    );

    (ballPitRef as MutableRefObject<BallPit>).current = {
      pop: () => {
        const planckBody = Utils.randomElement(balls)?.planckBody;
        if (planckBody) {
          planckBody.setAwake(true);
          planckBody.setLinearVelocity(new Vec2(Math.random() * 50 - 25, -500));
        }
      },
    };
    return (
      <canvas
        className={'ball-pit'}
        width={boardSize.current?.width}
        height={boardSize.current?.height}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
        }}
        ref={canvas}
      >
        {/*
        {balls.map((c) => (
          <div
            id={`ball-${c.index}`}
            key={`ball-${c.index}`}
            style={{position: 'absolute'}}
            className={`ball-scale ball ball-${['b', 'i', 'n', 'g', 'o'][c.index % 5]}`}
            onClick={() => {
              c.planckBody.setAwake(true);
              c.planckBody.setLinearVelocity(new Vec2(Math.random() * 50 - 25, -500));
            }}
          >
            <img alt={'ball'} src={c.emote.url} />
          </div>
        ))}
*/}
      </canvas>
    );
  }
);

const images: {[url: string]: HTMLImageElement} = {};

function getImage(url: string): HTMLImageElement {
  if (images[url]) return images[url];

  const image = new Image();
  image.src = url;
  images[url] = image;
  return image;
}
