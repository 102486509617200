export const Animals = [
  {image: './assets/animals/bear.png', name: 'Bear'},
  {image: './assets/animals/buffalo.png', name: 'Buffalo'},
  {image: './assets/animals/chick.png', name: 'Chick'},
  {image: './assets/animals/chicken.png', name: 'Chicken'},
  {image: './assets/animals/cow.png', name: 'Cow'},
  {image: './assets/animals/crocodile.png', name: 'Crocodile'},
  {image: './assets/animals/dog.png', name: 'Dog'},
  {image: './assets/animals/duck.png', name: 'Duck'},
  {image: './assets/animals/elephant.png', name: 'Elephant'},
  {image: './assets/animals/frog.png', name: 'Frog'},
  {image: './assets/animals/giraffe.png', name: 'Giraffe'},
  {image: './assets/animals/goat.png', name: 'Goat'},
  {image: './assets/animals/gorilla.png', name: 'Gorilla'},
  {image: './assets/animals/hippo.png', name: 'Hippo'},
  {image: './assets/animals/horse.png', name: 'Horse'},
  {image: './assets/animals/monkey.png', name: 'Monkey'},
  {image: './assets/animals/moose.png', name: 'Moose'},
  {image: './assets/animals/narwhal.png', name: 'Narwhal'},
  {image: './assets/animals/owl.png', name: 'Owl'},
  {image: './assets/animals/panda.png', name: 'Panda'},
  {image: './assets/animals/parrot.png', name: 'Parrot'},
  {image: './assets/animals/penguin.png', name: 'Penguin'},
  {image: './assets/animals/pig.png', name: 'Pig'},
  {image: './assets/animals/rabbit.png', name: 'Rabbit'},
  {image: './assets/animals/rhino.png', name: 'Rhino'},
  {image: './assets/animals/sloth.png', name: 'Sloth'},
  {image: './assets/animals/snake.png', name: 'Snake'},
  {image: './assets/animals/walrus.png', name: 'Walrus'},
  {image: './assets/animals/whale.png', name: 'Whale'},
  {image: './assets/animals/zebra.png', name: 'Zebra'},
];
