import '@common/game.scss';
import {FC, useState} from 'react';
import React from 'react';
import {useHistory, useParams} from 'react-router';
import {useWebStores} from '@common/store/webStores';
import {observer} from 'mobx-react';
import {useComponentWill} from '@common/hooks/useComponentWill';
import {AppToaster} from '@common/index';
import {Box} from '@common/components/box';
import {Loading} from '@common/components/loading';
import {I} from '@common/i18n/translator';
import {GameChannelClient} from '@common/dataServices/app.generated';

export const ConfigureGame: FC = observer(() => {
  const {channelAuthStore} = useWebStores();
  const {key} = useParams<{key: string}>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useComponentWill(async () => {
    if (key!) {
      const result = await GameChannelClient.startFromConfigurationKey(
        {configurationKey: key},
        {
          400: () => {},
        }
      );
      if (result) {
        channelAuthStore.setJWT(result.jwt);
        history.push('/game');
        return;
      }
    }
    AppToaster.show({
      intent: 'danger',
      message: I.get('generic.accountNotAuthorized'),
    });
    setLoading(false);
  });

  return (
    <>
      {loading && (
        <Box justify align style={{width: '100vw', height: '100vh'}}>
          <Loading />
        </Box>
      )}
    </>
  );
});
