import {authStore} from '../store/authStore';
import {channelAuthStore} from '../store/channelAuthStore';
import {AppConfig} from '../appConfig';
import {AppToaster} from '../index';

export const ClientTransformOptions = (options: any): RequestInit => {
  options.headers = options.headers ?? {};
  if (authStore?.token || channelAuthStore?.jwt) {
    options.headers.authorization = `Bearer ${authStore?.token || channelAuthStore?.jwt}`;
  }
  options.headers['Content-Type'] = 'application/json';
  options.headers.Accept = 'application/json';
  return options;
};

export const ClientOptions: ControllerOptions = {
  baseUrl: AppConfig.host,
  getJwt: () => {
    return '';
  },
  handleError: (error: string) => {
    if (error.toLowerCase().includes('fetch')) {
      error = 'Sorry, an error has occurred with EmoteBingo.';
    }
    AppToaster.show({message: error, intent: 'danger'});
  },
  handleUnauthorized: (error: string) => {
    authStore.logout();
    channelAuthStore.logout();
  },
};
export const ClientSocketOptions: ControllerOptions = {
  get baseUrl() {
    return AppConfig.socketHost + '?jwt=' + channelAuthStore.jwt;
  },
  getJwt: () => {
    return '';
  },
  handleError: (error: string) => {
    AppToaster.show({message: error, intent: 'danger'});
  },
  handleUnauthorized: (error: string) => {
    authStore.logout();
    channelAuthStore.logout();
  },
};

export const handle400 = {
  400: (result: {error: string}) => {
    AppToaster.show({message: result.error, intent: 'danger'});
  },
};

export interface ControllerOptions {
  baseUrl: string;
  getJwt: () => string;
  handleError: (error: string) => void;
  handleUnauthorized: (error: string) => void;
}
